import React from 'react'
import { Link } from 'react-router-dom'

import GraphQLLogo from '../assets/img/logo.svg'

const Page: React.FC = () => {
  return (
    <div className='main'>
      <div className='container'>
        <div className='logo'>
          <img src={GraphQLLogo} alt='graphql-logo'/>
        </div>
        <div className='title'>
          <Link to='/login' className='link block'>Login</Link>
          &nbsp; to SISURAT
        </div>
      </div>
    </div>
  )
}

export default Page
