import {
  TempState,
  TempActions,
  SET_SURAT_MASUK_TEMP,
  SET_DISPOSISI_TEMP,
  RESET_TEMP
} from '../types/temp'

const initialState: TempState = {}

export default function (state = initialState, action: TempActions): TempState {

  switch (action.type) {

    case SET_SURAT_MASUK_TEMP:
      return {
        ...state,
        surat_masuk: action.payload
      }

    case SET_DISPOSISI_TEMP:
      return {
        ...state,
        disposisi: action.payload
      }

    case RESET_TEMP:
      return initialState

    default:
      return state

  }

}
