import React from 'react'
import { connect } from 'react-redux'
import { MaterialTableProps } from 'material-table'

import required from '../../../utils/required'
import { RootStates } from '../../../store/reducers'
import { ThunkDispatchType } from '../../../store/actions/_thunk'
import { ID } from '../../../graphql'
import { Jabatan, JabatanInput } from '../../../graphql/jabatan'
import {
  fetchJabatans,
  createJabatan,
  updateJabatan,
  deleteJabatan,
  clearError as jabatanClearError
} from '../../../store/actions/jabatan'
import {
  fetchRoles,
  clearError as roleClearError
} from '../../../store/actions/role'
import MainTitle from '../../../components/main-title'
import MySnackbar from '../../../components/snackbar'
import TableComponent from '../../../components/table'

const mapStateToProps = ({ jabatan, role }: RootStates) => ({ jabatan, role })
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  fetchJabatans: () => dispatch(fetchJabatans()),
  fetchRoles: () => dispatch(fetchRoles()),
  createJabatan: (data: JabatanInput) => dispatch(createJabatan(data)),
  updateJabatan: (_id: ID, data: JabatanInput) => dispatch(updateJabatan(_id, data)),
  deleteJabatan: (_id: ID) => dispatch(deleteJabatan(_id)),
  clearError: () => {
    dispatch(jabatanClearError())
    dispatch(roleClearError())
  }
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type Row = {
  _id: ID
  nama: string
  role: ID
  users: Jabatan['users']
}

type Table = MaterialTableProps<Row>

class JabatanPage extends React.Component<Props> {
  constructor (props: Props) {
    super(props)
    props.fetchRoles()
    props.fetchJabatans()
  }

  render () {
    const { error: jabatanError, jabatans, loading: jabatanLoading } = this.props.jabatan
    const { error: roleError, roles, loading: roleLoading } = this.props.role

    const loading = jabatanLoading || roleLoading
    const error = jabatanError || roleError
    const { clearError } = this.props

    // mapping for data tables
    const lookupRoles = roles.reduce((state, role) => ({
      ...state,
      [role._id]: role.nama
    }), {})
    const mapJabatans: Row[] = jabatans.map(jabatan => ({
      ...jabatan,
      role: jabatan.role._id
    }))

    const data: Table['data'] = mapJabatans
    const columns: Table['columns'] = [
      {
        field: '_id',
        title: 'ID',
        hidden: true,
        editable: 'never'
      },
      {
        field: 'nama',
        title: 'Nama'
      },
      {
        field: 'role',
        title: 'Role',
        lookup: lookupRoles
      }
    ]
    const editable: Table['editable'] = {
      isDeletable: rowData => rowData.users.length <= 0,
      onRowAdd: async newData => {
        const data: JabatanInput = {
          nama: newData.nama,
          role: newData.role
        }
        const validation = required('nama|role', data)
        if (validation.error) {
          alert(validation.message)
          throw new Error(validation.message || '')
        } else
          this.props.createJabatan(data)
      },
      onRowUpdate: async (newData, oldData) => {
        const data: JabatanInput = {
          nama: newData.nama,
          role: newData.role
        }
        const validation = required('nama|role', data)
        if (validation.error) {
          alert(validation.message)
          throw new Error(validation.message || '')
        } else
          this.props.updateJabatan(oldData?._id || '', data)
      },
      onRowDelete: async oldData => {
        this.props.deleteJabatan(oldData._id)
      }
    }

    return (
      <div>
        <MySnackbar
          open={error ? true : false}
          message={error}
          onClose={clearError} />
        <MainTitle title='Jabatan'/>
        <TableComponent<Row>
          isLoading={loading}
          columns={columns}
          data={data}
          editable={editable}/>
      </div>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JabatanPage)
