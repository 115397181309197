import { User as UserSchema } from '../../graphql/user'

export const REQUEST_FETCH_USERS = 'REQUEST_FETCH_USERS'
export const REQUEST_CREATE_USER = 'REQUEST_CREATE_USER'
export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER'
export const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER'
export const REQUEST_USER_ERROR = 'REQUEST_USER_ERROR'
export const SET_USERS = 'SET_USERS'
export const CLEAR_USER = 'CLEAR_USER'
export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR'

export type User = UserSchema

export type UserState = {
  users: User[]
  loading: boolean
  error: string | null
}

export type RequestFetchUsersAction = {
  type: typeof REQUEST_FETCH_USERS
}

export type RequestCreateUserAction = {
  type: typeof REQUEST_CREATE_USER
}

export type RequestUpdateUserAction = {
  type: typeof REQUEST_UPDATE_USER
}

export type RequestDeleteUserAction = {
  type: typeof REQUEST_DELETE_USER
}

export type RequestUserErrorAction = {
  type: typeof REQUEST_USER_ERROR
  payload: string
}

export type SetUsersAction = {
  type: typeof SET_USERS
  payload: User[]
}

export type ClearUserAction = {
  type: typeof CLEAR_USER
}

export type ClearUserErrorAction = {
  type: typeof CLEAR_USER_ERROR
}

export type UserActions =
  RequestFetchUsersAction |
  RequestCreateUserAction |
  RequestUpdateUserAction |
  RequestDeleteUserAction |
  RequestUserErrorAction |
  SetUsersAction |
  ClearUserAction |
  ClearUserErrorAction
