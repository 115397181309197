import { Disposisi as DisposisiSchema } from '../../graphql/disposisi'

export const REQUEST_FETCH_DISPOSISIS = 'REQUEST_FETCH_DISPOSISIS'
export const REQUEST_CREATE_DISPOSISI = 'REQUEST_CREATE_DISPOSISI'
export const REQUEST_UPDATE_DISPOSISI = 'REQUEST_UPDATE_DISPOSISI'
export const REQUEST_DELETE_DISPOSISI = 'REQUEST_DELETE_DISPOSISI'
export const REQUEST_DISPOSISI_ERROR = 'REQUEST_DISPOSISI_ERROR'
export const SET_DISPOSISIS = 'SET_DISPOSISIS'
export const CLEAR_DISPOSISI = 'CLEAR_DISPOSISI'
export const CLEAR_DISPOSISI_ERROR = 'CLEAR_DISPOSISI_ERROR'

export type Disposisi = DisposisiSchema

export type DisposisiState = {
  disposisis: Disposisi[]
  loading: boolean
  error: string | null
}

export type RequestFetchDisposisisAction = {
  type: typeof REQUEST_FETCH_DISPOSISIS
}

export type RequestCreateDisposisiAction = {
  type: typeof REQUEST_CREATE_DISPOSISI
}

export type RequestUpdateDisposisiAction = {
  type: typeof REQUEST_UPDATE_DISPOSISI
}

export type RequestDeleteDisposisiAction = {
  type: typeof REQUEST_DELETE_DISPOSISI
}

export type RequestDisposisiErrorAction = {
  type: typeof REQUEST_DISPOSISI_ERROR
  payload: string
}

export type SetDisposisisAction = {
  type: typeof SET_DISPOSISIS
  payload: Disposisi[]
}

export type ClearDisposisiAction = {
  type: typeof CLEAR_DISPOSISI
}

export type ClearDisposisiErrorAction = {
  type: typeof CLEAR_DISPOSISI_ERROR
}

export type DisposisiActions =
  RequestFetchDisposisisAction |
  RequestCreateDisposisiAction |
  RequestUpdateDisposisiAction |
  RequestDeleteDisposisiAction |
  RequestDisposisiErrorAction |
  SetDisposisisAction |
  ClearDisposisiAction |
  ClearDisposisiErrorAction
