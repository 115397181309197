import { User } from '../../graphql/user'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SET_SESSION = 'SET_SESSION'
export const CLEAR_SESSION = 'CLEAR_SESSION'
export const CLEAR_SESSION_ERROR = 'CLEAR_SESSION_ERROR'
export const CLEAR_SESSION_LOADING = 'CLEAR_SESSION_LOADING'
export const SESSION_HAS_EXPIRED = 'SESSION_HAS_EXPIRED'

export type Session = User

export type SessionState = {
  info: Session | null
  loading: boolean
  error: string | null
  expireAt: Date | null
}

export type LoginRequestAction = {
  type: typeof LOGIN_REQUEST
}

export type LoginErrorAction = {
  type: typeof LOGIN_ERROR
  payload: string
}

export type SetSessionAction = {
  type: typeof SET_SESSION
  payload: Session
}

export type ClearSessionErrorAction = {
  type: typeof CLEAR_SESSION_ERROR
}

export type ClearSessionLoadingAction = {
  type: typeof CLEAR_SESSION_LOADING
}

export type ClearSessionAction = {
  type: typeof CLEAR_SESSION
}

export type SessionHasExpiredAction = {
  type: typeof SESSION_HAS_EXPIRED
  payload: string
}

export type SessionActions =
  SetSessionAction |
  ClearSessionAction |
  LoginRequestAction |
  ClearSessionErrorAction |
  ClearSessionLoadingAction |
  LoginErrorAction |
  SessionHasExpiredAction
