import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { MaterialTableProps } from 'material-table'
import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import required from '../utils/required'
import { RootStates } from '../store/reducers'
import { ThunkDispatchType } from '../store/actions/_thunk'
import {
  createSuratKeluar,
  updateSuratKeluar
} from '../store/actions/surat-keluar'
import { ID } from '../graphql'
import { SuratKeluar, SuratKeluarInput } from '../graphql/surat-keluar'
import { KebutuhanAccInput } from '../graphql/kebutuhan-acc'
import { Arsip } from '../graphql/arsip'
import { Sifat } from '../graphql/sifat'
import {
  richTextEditor as RichTextEditor,
  table as TableComponent
} from './'

const mapStateToProps = ({ suratKeluar }: RootStates) => ({ suratKeluar })
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  createSuratKeluar: (data: SuratKeluarInput) => dispatch(createSuratKeluar(data)),
  updateSuratKeluar: (_id: ID, data: SuratKeluarInput) => dispatch(updateSuratKeluar(_id, data))
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  {
    readOnly?: boolean
    suratKeluarId?: ID
    open: boolean
    setOpen: (open: boolean) => void
    arsips: Arsip[]
    sifats: Sifat[]
  }

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff'
    }
  })
))

const initialInput: SuratKeluarInput = {
  nomor: '',
  tujuan: '',
  lampiran: 0,
  isi: '',
  arsip: '',
  sifat: '',
  kebutuhan_accs: [],
}

const toInt: (value: string) => number = (value) => {
  const parsed = parseInt(value)
  return isNaN(parsed) ? 0 : parsed
}

type Row = {
  id: number
  nama: string
  jabatan?: string
  nip?: string
  lingkup?: string
}

type Table = MaterialTableProps<Row>

type TableProps = {
  disabled: boolean
  data: KebutuhanAccInput[]
  setData: (data: KebutuhanAccInput[]) => void
}

const KebutuhanAccTable: React.FC<TableProps> = (props) => {
  let { disabled, data, setData } = props

  // mapping data
  const mapData: Row[] = data.map((data, id) => ({
    ...data, id
  }))
  const columns: Table['columns'] = [
    {
      field: 'nama',
      title: 'Nama'
    },
    {
      field: 'nip',
      title: 'NIP'
    },
    {
      field: 'jabatan',
      title: 'Jabatan'
    },
    {
      field: 'lingkup',
      title: 'Lingkup'
    }
  ]
  const editable: Table['editable'] = disabled ? undefined : {
    onRowAdd: async newData => {
      const data: KebutuhanAccInput = {
        nama: newData.nama,
        jabatan: newData.jabatan,
        lingkup: newData.lingkup,
        nip: newData.nip
      }
      const validation = required('nama', data)
      if (validation.error) {
        alert(validation.message)
        throw new Error(validation.message || '')
      } else {
        const remapData = mapData.map(data => ({
          nama: data.nama,
          jabatan: data.jabatan,
          lingkup: data.lingkup,
          nip: data.nip
        }))
        setData([...remapData, data])
      }
    },
    onRowUpdate: async (newData, oldData) => {
      const data: KebutuhanAccInput = {
        nama: newData.nama,
        jabatan: newData.jabatan,
        lingkup: newData.lingkup,
        nip: newData.nip
      }
      const validation = required('nama', data)
      if (validation.error) {
        alert(validation.message)
        throw new Error(validation.message || '')
      } else {
        const updateData = mapData.map(curr => (
          curr.id === oldData?.id
            ? data
            : curr
        ))
        const remapData = updateData.map(data => ({
          nama: data.nama,
          jabatan: data.jabatan,
          lingkup: data.lingkup,
          nip: data.nip
        }))
        setData(remapData)
      }
    },
    onRowDelete: async (oldData) => {
      const deleteData = mapData.filter(({ id }) => id !== oldData.id)
      const remapData = deleteData.map(data => ({
        nama: data.nama,
        jabatan: data.jabatan,
        lingkup: data.lingkup,
        nip: data.nip
      }))
      setData(remapData)
    }
  }

  const tableHeight = '40vh'

  return (
    <TableComponent
      title='Kebutuhan Acc'
      options={{
        minBodyHeight: tableHeight,
        maxBodyHeight: tableHeight,
        toolbarButtonAlignment: 'right',
        showTitle: true,
        pageSize: 3,
        pageSizeOptions: [3,6,12],
      }}
      data={mapData}
      editable={editable}
      columns={columns}/>
  )
}

const SuratKeluarDialog: React.FC<Props> = (props) => {
  const {
    readOnly = false,
    suratKeluarId,
    open,
    setOpen,
    suratKeluar: { suratKeluars, loading },
    arsips,
    sifats,
    createSuratKeluar,
    updateSuratKeluar
  } = props

  let mode: 'update' | 'create' = suratKeluarId ? 'update' : 'create'
  const [input, setInput] = useState<SuratKeluarInput>(initialInput)
  const [disabled, setDisabled] = useState<boolean>(readOnly || false)

  const closeModal = useRef(setOpen)

  useEffect(() => {
    const currentData: SuratKeluar | undefined = suratKeluars
      .find(({ _id }) => suratKeluarId === _id)

    if (currentData) {
      setInput({
        ...currentData,
        arsip: currentData.arsip._id,
        sifat: currentData.sifat._id
      })
      setDisabled(readOnly || currentData.status === 1)
    } else {
      setInput(initialInput)
      setDisabled(readOnly || false)
    }
  }, [suratKeluarId, suratKeluars, readOnly])

  useEffect(() => {
    closeModal.current = setOpen
  }, [setOpen])

  useEffect(() => {
    closeModal.current(false)
  }, [suratKeluars])

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput({
      ...input,
      [e.target.name]: e.target.type === 'number'
        ? toInt(e.target.value)
        : e.target.value
    })
  }

  const onChangeSelect = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name = '' } = e.target
    setInput({
      ...input,
      [name]: e.target.value
    })
  }

  const onChangeKebutuhanAcc = (kebutuhan_accs: KebutuhanAccInput[]) => {
    setInput({
      ...input,
      kebutuhan_accs,
    })
  }

  const submit = {
    create: () => {
      const data = {
        nomor: input.nomor,
        lampiran: input.lampiran,
        tujuan: input.tujuan,
        isi: input.isi,
        arsip: input.arsip,
        sifat: input.sifat,
        kebutuhan_accs: input.kebutuhan_accs.map(data => ({
          nama: data.nama,
          jabatan: data.jabatan,
          lingkup: data.lingkup,
          nip: data.nip
        }))
      }
      const validation = required('nomor|tujuan|isi|arsip|sifat|kebutuhan_accs', data)
      if (validation.error) {
        alert(validation.message)
      } else
        createSuratKeluar(data)
    },
    update: () => {
      const data = {
        nomor: input.nomor,
        lampiran: input.lampiran,
        tujuan: input.tujuan,
        isi: input.isi,
        arsip: input.arsip,
        sifat: input.sifat,
        kebutuhan_accs: input.kebutuhan_accs.map(data => ({
          nama: data.nama,
          jabatan: data.jabatan,
          lingkup: data.lingkup,
          nip: data.nip
        }))
      }
      const validation = required('nomor|tujuan|isi|arsip|sifat|kebutuhan_accs', data)
      if (validation.error) {
        alert(validation.message)
      } else
        updateSuratKeluar((suratKeluarId || ''), data)
    }
  }

  const classes = useStyles()

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        open={open}
        fullScreen={true}
        onClose={() => setOpen(false)}>
        <DialogTitle>
          Surat Keluar
          {
            disabled && !readOnly
              ? <Typography
                  variant='body2'
                  color='textSecondary'>
                  Data tidak dapat diubah karena telah disetujui
                </Typography>
              : null
          }
        </DialogTitle>
        <DialogContent style={{ overflowX: 'hidden' }}>
          <form onSubmit={e => e.preventDefault()}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id='select-arsip' color='secondary'>Arsip Surat</InputLabel>
                  <Select
                    color='secondary'
                    disabled={disabled}
                    autoWidth
                    name='arsip'
                    labelId='select-arsip'
                    value={input.arsip}
                    onChange={onChangeSelect}>
                    {
                      arsips.map((arsip, i) => (
                        <MenuItem
                          key={i}
                          value={arsip._id}>
                          {arsip.nama}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id='select-sifat' color='secondary'>Sifat Surat</InputLabel>
                  <Select
                    color='secondary'
                    disabled={disabled}
                    autoWidth
                    name='sifat'
                    labelId='select-sifat'
                    value={input.sifat}
                    onChange={onChangeSelect}>
                    {
                      sifats.map((sifat, i) => (
                        <MenuItem
                          key={i}
                          value={sifat._id}>
                          {sifat.nama}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  color='secondary'
                  disabled={disabled}
                  fullWidth
                  name='nomor'
                  label='Nomor'
                  variant='outlined'
                  value={input.nomor}
                  onChange={onChangeInput}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  color='secondary'
                  disabled={disabled}
                  fullWidth
                  name='tujuan'
                  label='Tujuan'
                  variant='outlined'
                  value={input.tujuan}
                  onChange={onChangeInput}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  color='secondary'
                  disabled={disabled}
                  fullWidth
                  type='number'
                  name='lampiran'
                  label='Lampiran'
                  variant='outlined'
                  value={input.lampiran}
                  onChange={onChangeInput}/>
              </Grid>
              <Grid item xs={12}>
                <div className='rich-text-editor'>
                  {
                    disabled
                      ? <div className='preview' dangerouslySetInnerHTML={{ __html: input.isi }}></div>
                      : <RichTextEditor
                          value={input.isi}
                          onChange={value => setInput({
                            ...input,
                            isi: value
                          })}/>
                  }
                </div>
              </Grid>
              <Grid item xs={12}>
                <KebutuhanAccTable
                  disabled={disabled}
                  data={input.kebutuhan_accs}
                  setData={onChangeKebutuhanAcc}/>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='secondary'>
            Cancel
          </Button>
          {
            disabled
              ? null
              : (
                  <Button onClick={() => submit[mode]()} color='secondary' autoFocus>
                    Save
                  </Button>
                )
          }
        </DialogActions>
      </Dialog>
      <Backdrop open={loading && open} className={classes.backdrop}>
        <CircularProgress color='inherit'/>
      </Backdrop>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuratKeluarDialog)
