import React from 'react'
import { connect } from 'react-redux'
import { MaterialTableProps } from 'material-table'

import required from '../../../utils/required'
import { RootStates } from '../../../store/reducers'
import { ThunkDispatchType } from '../../../store/actions/_thunk'
import { ID } from '../../../graphql'
import { Arsip, ArsipInput } from '../../../graphql/arsip'
import {
  fetchArsips,
  createArsip,
  updateArsip,
  deleteArsip,
  clearError
} from '../../../store/actions/arsip'
import MainTitle from '../../../components/main-title'
import MySnackbar from '../../../components/snackbar'
import TableComponent from '../../../components/table'

const mapStateToProps = ({ arsip }: RootStates) => ({ arsip })
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  fetchArsips: () => dispatch(fetchArsips()),
  createArsip: (data: ArsipInput) => dispatch(createArsip(data)),
  updateArsip: (_id: ID, data: ArsipInput) => dispatch(updateArsip(_id, data)),
  deleteArsip: (_id: ID) => dispatch(deleteArsip(_id)),
  clearError: () => dispatch(clearError())
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type Row = {
  _id: ID
  nama: string
  kode: string
  deskripsi?: string
  surat_masuks: Arsip['surat_masuks']
  surat_keluars: Arsip['surat_keluars']
}

type Table = MaterialTableProps<Row>

class ArsipPage extends React.Component<Props> {
  constructor (props: Props) {
    super(props)
    props.fetchArsips()
  }

  render () {
    const { arsips, loading, error } = this.props.arsip
    const { clearError } = this.props

    const data: Table['data'] = arsips
    const columns: Table['columns'] = [
      {
        field: '_id',
        title: 'ID',
        hidden: true,
        editable: 'never'
      },
      {
        field: 'kode',
        title: 'Kode'
      },
      {
        field: 'nama',
        title: 'Nama'
      },
      {
        field: 'deskripsi',
        title: 'Deskripsi',
        hidden: true,
        render: rowData => rowData.deskripsi ? `${rowData.deskripsi}` : '-'
      }
    ]
    const editable: Table['editable'] = {
      isDeletable: rowData => (rowData.surat_masuks.length + rowData.surat_keluars.length) <= 0,
      onRowAdd: async newData => {
        const data: ArsipInput = {
          nama: newData.nama,
          kode: newData.kode,
          deskripsi: newData.deskripsi
        }
        const validation = required('nama|kode', data)
        if (validation.error) {
          alert(validation.message)
          throw new Error(validation.message || '')
        } else
          this.props.createArsip(data)
      },
      onRowUpdate: async (newData, oldData) => {
        const data: ArsipInput = {
          nama: newData.nama,
          kode: newData.kode,
          deskripsi: newData.deskripsi
        }
        const validation = required('nama|kode', data)
        if (validation.error) {
          alert(validation.message)
          throw new Error(validation.message || '')
        } else
          this.props.updateArsip(oldData?._id || '', data)
      },
      onRowDelete: async oldData => {
        this.props.deleteArsip(oldData._id)
      }
    }

    return (
      <div>
        <MySnackbar
          open={error ? true : false}
          message={error}
          onClose={clearError} />
        <MainTitle title='Arsip'/>
        <TableComponent<Row>
          isLoading={loading}
          columns={columns}
          data={data}
          editable={editable}/>
      </div>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArsipPage)
