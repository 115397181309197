import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

const NotFoundDashboardPage: React.FC = () => {
  const status = 404
  const message = 'Page not found'
  const classes = makeStyles(({
    title: { color: grey[800] },
    subtitle: { color: grey[700] }
  }))()

  return (
    <div className='exception'>
      <p className={`title ${classes.title}`}>
        OOPS!
      </p>
      <p className={`subtitle ${classes.subtitle}`}>
        {`Error: ${status} ${message}`}
      </p>
    </div>
  )
}

export default NotFoundDashboardPage
