import { ThunkActionType } from './_thunk'
import { gqlRequest, gqlFileRequest, GraphQLResponse } from './_graphql'
import {
  REQUEST_FETCH_SURAT_MASUKS,
  REQUEST_CREATE_SURAT_MASUK,
  REQUEST_UPDATE_SURAT_MASUK,
  REQUEST_DELETE_SURAT_MASUK,
  REQUEST_SURAT_MASUK_ERROR,
  SET_SURAT_MASUKS,
  CLEAR_SURAT_MASUK,
  CLEAR_SURAT_MASUK_ERROR
} from '../types/surat-masuk'
import { ID } from '../../graphql'
import { SuratMasuk, SuratMasukInput } from '../../graphql/surat-masuk'

const FETCH_SURAT_MASUKS = () => ({
  query: `
    query { suratMasuks {
      _id berkas nomor lampiran sumber isi status
      arsip { _id nama kode deskripsi }
      sifat { _id nama }
      disposisi { _id catatan perintah createdAt updatedAt
        users { _id email nama nip
        jabatan { _id nama role { _id nama level } }
        balasan
      } }
      createdAt updatedAt
    } }
  `
})
const CREATE_SURAT_MASUK = (data: SuratMasukInput) => ({
  query: `
    mutation createSuratMasuk ($data: SuratMasukInput!) {
      createSuratMasuk (data: $data) { _id }
    }
  `,
  variables: { data }
})
const UPDATE_SURAT_MASUK = (_id: ID, data: SuratMasukInput) => ({
  query: `
    mutation updateSuratMasuk ($_id: ID! $data: SuratMasukInput!) {
      updateSuratMasuk (_id: $_id data: $data) { _id }
    }
  `,
  variables: { _id, data }
})
const DELETE_SURAT_MASUK = (_id: ID) => ({
  query: `
    mutation deleteSuratMasuk ($_id: ID!) {
      deleteSuratMasuk (_id: $_id) { _id }
    }
  `,
  variables: { _id }
})

export function fetchSuratMasuks (): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_FETCH_SURAT_MASUKS })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      const response = await gqlRequest<GraphQLResponse<{suratMasuks: SuratMasuk[]}>>(FETCH_SURAT_MASUKS(), auth)
      dispatch({
        type: SET_SURAT_MASUKS,
        payload: response.suratMasuks
      })
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_MASUK_ERROR,
        payload: err.message
      })
    }
  }
}

export function createSuratMasuk (data: SuratMasukInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_CREATE_SURAT_MASUK })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlFileRequest<GraphQLResponse<{createSuratMasuk: SuratMasuk}>>(CREATE_SURAT_MASUK(data), auth, 'data.berkas')
      await fetchSuratMasuks()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_MASUK_ERROR,
        payload: err.message
      })
    }
  }
}

export function updateSuratMasuk (_id: ID, data: SuratMasukInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_UPDATE_SURAT_MASUK })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlFileRequest<GraphQLResponse<{updateSuratMasuk: SuratMasuk}>>(UPDATE_SURAT_MASUK(_id, data), auth, 'data.berkas')
      await fetchSuratMasuks()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_MASUK_ERROR,
        payload: err.message
      })
    }
  }
}

export function deleteSuratMasuk (_id: ID): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_SURAT_MASUK })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{deleteSuratMasuk: SuratMasuk}>>(DELETE_SURAT_MASUK(_id), auth)
      await fetchSuratMasuks()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_MASUK_ERROR,
        payload: err.message
      })
    }
  }
}

export function clearSuratMasuk (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_SURAT_MASUK })
  }
}

export function clearError (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_SURAT_MASUK_ERROR })
  }
}
