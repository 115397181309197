import { ThunkActionType } from './_thunk'
import { gqlRequest, GraphQLResponse } from './_graphql'
import {
  REQUEST_FETCH_ARSIPS,
  REQUEST_CREATE_ARSIP,
  REQUEST_UPDATE_ARSIP,
  REQUEST_DELETE_ARSIP,
  REQUEST_ARSIP_ERROR,
  SET_ARSIPS,
  CLEAR_ARSIP,
  CLEAR_ARSIP_ERROR
} from '../types/arsip'
import { ID } from '../../graphql'
import { Arsip, ArsipInput } from '../../graphql/arsip'

const FETCH_ARSIPS = () => ({
  query: `
    query { arsips {
      _id nama kode deskripsi
      surat_masuks { _id }
      surat_keluars { _id }
      createdAt updatedAt
    } }
  `
})
const CREATE_ARSIP = (data: ArsipInput) => ({
  query: `
    mutation createArsip ($data: ArsipInput!) {
      createArsip (data: $data) { _id }
    }
  `,
  variables: { data }
})
const UPDATE_ARSIP = (_id: ID, data: ArsipInput) => ({
  query: `
    mutation updateArsip ($_id: ID! $data: ArsipInput!) {
      updateArsip (_id: $_id data: $data) { _id }
    }
  `,
  variables: { _id, data }
})
const DELETE_ARSIP = (_id: ID) => ({
  query: `
    mutation deleteArsip ($_id: ID!) {
      deleteArsip (_id: $_id) { _id }
    }
  `,
  variables: { _id }
})

export function fetchArsips (): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_FETCH_ARSIPS })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      const response = await gqlRequest<GraphQLResponse<{arsips: Arsip[]}>>(FETCH_ARSIPS(), auth)
      dispatch({
        type: SET_ARSIPS,
        payload: response.arsips
      })
    } catch (err) {
      dispatch({
        type: REQUEST_ARSIP_ERROR,
        payload: err.message
      })
    }
  }
}

export function createArsip (data: ArsipInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_CREATE_ARSIP })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{createArsip: Arsip}>>(CREATE_ARSIP(data), auth)
      await fetchArsips()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_ARSIP_ERROR,
        payload: err.message
      })
    }
  }
}

export function updateArsip (_id: ID, data: ArsipInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_UPDATE_ARSIP })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{updateArsip: Arsip}>>(UPDATE_ARSIP(_id, data), auth)
      await fetchArsips()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_ARSIP_ERROR,
        payload: err.message
      })
    }
  }
}

export function deleteArsip (_id: ID): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_ARSIP })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{deleteArsip: Arsip}>>(DELETE_ARSIP(_id), auth)
      await fetchArsips()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_ARSIP_ERROR,
        payload: err.message
      })
    }
  }
}

export function clearArsip (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_ARSIP })
  }
}

export function clearError (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_ARSIP_ERROR })
  }
}
