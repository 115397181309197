import React from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

type Props = {
  value: string
  onChange: (value: string) => void
}

const RichTextEditor: React.FC<Props> = (props) => {
  const { value, onChange } = props

  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      onChange={(_e: any,value: any) => onChange(value.getData())}
      config={{
        tabSpaces: 4,
        toolbar: [
          'heading', '|',
          'bold', 'italic'
        ]}}/>
  )
}

export default RichTextEditor
