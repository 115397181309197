import { ThunkActionType } from './_thunk'
import { gqlRequest, GraphQLResponse } from './_graphql'
import {
  REQUEST_FETCH_ROLES,
  REQUEST_ROLE_ERROR,
  SET_ROLES,
  CLEAR_ROLE,
  CLEAR_ROLE_ERROR
} from '../types/role'
import { Role } from '../../graphql/role'

const FETCH_ROLES = () => ({
  query: `
    query { roles {
      _id nama level
      createdAt updatedAt
    } }
  `
})

export function fetchRoles (): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_FETCH_ROLES })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      const response = await gqlRequest<GraphQLResponse<{roles: Role[]}>>(FETCH_ROLES(), auth)
      dispatch({
        type: SET_ROLES,
        payload: response.roles
      })
    } catch (err) {
      dispatch({
        type: REQUEST_ROLE_ERROR,
        payload: err.message
      })
    }
  }
}

export function clearRole (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_ROLE })
  }
}

export function clearError (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_ROLE_ERROR })
  }
}
