import {
  REQUEST_FETCH_ROLES,
  REQUEST_ROLE_ERROR,
  SET_ROLES,
  CLEAR_ROLE,
  CLEAR_ROLE_ERROR,
  RoleState,
  RoleActions
} from '../types/role'

const initialState: RoleState = {
  roles: [],
  loading: false,
  error: null
}

export default function (state = initialState, action: RoleActions): RoleState {

  switch (action.type) {

    case REQUEST_FETCH_ROLES:
      return {
        ...state,
        loading: true,
        error: null
      }

    case REQUEST_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case SET_ROLES:
      return {
        ...state,
        loading: false,
        error: null,
        roles: action.payload
      }

    case CLEAR_ROLE:
      return initialState

    case CLEAR_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        error: null
      }

    default:
      return state

  }

}
