type Required = {
  error: boolean
  message: string | null
}

export default function (requiredKey: string, obj: { [key: string]: any }): Required {
  const splittedKey = requiredKey.split('|')
  return splittedKey.reduce((state: Required, key: string) => (
    !state.error
      ? obj[key] === '' || !obj[key]
        ? { error: true, message: `${key} is required` }
        : state
      : state
  ), { error: false, message: null })
}
