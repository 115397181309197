import {
  JabatanState,
  JabatanActions,
  REQUEST_FETCH_JABATANS,
  REQUEST_CREATE_JABATAN,
  REQUEST_UPDATE_JABATAN,
  REQUEST_DELETE_JABATAN,
  REQUEST_JABATAN_ERROR,
  SET_JABATANS,
  CLEAR_JABATAN,
  CLEAR_JABATAN_ERROR,
} from '../types/jabatan'

const initialState: JabatanState = {
  jabatans: [],
  loading: false,
  error: null
}

export default function (state = initialState, action: JabatanActions): JabatanState {

  switch (action.type) {

    case REQUEST_FETCH_JABATANS:
    case REQUEST_CREATE_JABATAN:
    case REQUEST_UPDATE_JABATAN:
    case REQUEST_DELETE_JABATAN:
      return {
        ...state,
        loading: true,
        error: null
      }

    case REQUEST_JABATAN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case SET_JABATANS:
      return {
        ...state,
        loading: false,
        error: null,
        jabatans: action.payload
      }

    case CLEAR_JABATAN:
      return initialState

    case CLEAR_JABATAN_ERROR:
      return {
        ...state,
        loading: false,
        error: null
      }

    default:
      return state

  }

}
