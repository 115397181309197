import {
  SuratKeluarState,
  SuratKeluarActions,
  REQUEST_FETCH_SURAT_KELUARS,
  REQUEST_CREATE_SURAT_KELUAR,
  REQUEST_UPDATE_SURAT_KELUAR,
  REQUEST_DELETE_SURAT_KELUAR,
  REQUEST_SURAT_KELUAR_ERROR,
  REQUEST_ACC_SURAT_KELUAR,
  SET_SURAT_KELUARS,
  CLEAR_SURAT_KELUAR,
  CLEAR_SURAT_KELUAR_ERROR
} from '../types/surat-keluar'

const initialState: SuratKeluarState = {
  suratKeluars: [],
  loading: false,
  error: null
}

export default function (state = initialState, action: SuratKeluarActions): SuratKeluarState {

  switch (action.type) {

    case REQUEST_FETCH_SURAT_KELUARS:
    case REQUEST_CREATE_SURAT_KELUAR:
    case REQUEST_UPDATE_SURAT_KELUAR:
    case REQUEST_DELETE_SURAT_KELUAR:
    case REQUEST_ACC_SURAT_KELUAR:
      return {
        ...state,
        loading: true,
        error: null
      }

    case REQUEST_SURAT_KELUAR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case SET_SURAT_KELUARS:
      return {
        ...state,
        loading: false,
        error: null,
        suratKeluars: action.payload
      }

    case CLEAR_SURAT_KELUAR:
      return initialState

    case CLEAR_SURAT_KELUAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null
      }

    default:
      return state

  }

}
