import {
  DisposisiState,
  DisposisiActions,
  REQUEST_FETCH_DISPOSISIS,
  REQUEST_CREATE_DISPOSISI,
  REQUEST_UPDATE_DISPOSISI,
  REQUEST_DELETE_DISPOSISI,
  REQUEST_DISPOSISI_ERROR,
  SET_DISPOSISIS,
  CLEAR_DISPOSISI,
  CLEAR_DISPOSISI_ERROR
} from '../types/disposisi'

const initialState: DisposisiState = {
  disposisis: [],
  loading: false,
  error: null
}

export default function (state = initialState, action: DisposisiActions): DisposisiState {

  switch (action.type) {

    case REQUEST_FETCH_DISPOSISIS:
    case REQUEST_CREATE_DISPOSISI:
    case REQUEST_UPDATE_DISPOSISI:
    case REQUEST_DELETE_DISPOSISI:
      return {
        ...state,
        loading: true,
        error: null
      }

    case REQUEST_DISPOSISI_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case SET_DISPOSISIS:
      return {
        ...state,
        loading: false,
        error: null,
        disposisis: action.payload
      }

    case CLEAR_DISPOSISI:
      return initialState

    case CLEAR_DISPOSISI_ERROR:
      return {
        ...state,
        loading: false,
        error: null
      }

    default:
      return state

  }

}
