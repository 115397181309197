import { ThunkActionType } from './_thunk'
import {
  SET_SURAT_MASUK_TEMP,
  SET_DISPOSISI_TEMP,
  RESET_TEMP
} from '../types/temp'
import { ID } from '../../graphql'

export function setSuratMasukTemp (_id?: ID): ThunkActionType {
  return dispatch => {
    dispatch({
      type: SET_SURAT_MASUK_TEMP,
      payload: _id
    })
  }
}

export function setDisposisiTemp (_id?: ID): ThunkActionType {
  return dispatch => {
    dispatch({
      type: SET_DISPOSISI_TEMP,
      payload: _id
    })
  }
}

export function resetTemp (): ThunkActionType {
  return dispatch => {
    dispatch({ type: RESET_TEMP })
  }
}
