import { Jabatan as JabatanSchema } from '../../graphql/jabatan'

export const REQUEST_FETCH_JABATANS = 'REQUEST_FETCH_JABATANS'
export const REQUEST_CREATE_JABATAN = 'REQUEST_CREATE_JABATAN'
export const REQUEST_UPDATE_JABATAN = 'REQUEST_UPDATE_JABATAN'
export const REQUEST_DELETE_JABATAN = 'REQUEST_DELETE_JABATAN'
export const REQUEST_JABATAN_ERROR = 'REQUEST_JABATAN_ERROR'
export const SET_JABATANS = 'SET_JABATANS'
export const CLEAR_JABATAN = 'CLEAR_JABATAN'
export const CLEAR_JABATAN_ERROR = 'CLEAR_JABATAN_ERROR'

export type Jabatan = JabatanSchema

export type JabatanState = {
  jabatans: Jabatan[]
  loading: boolean
  error: string | null
}

export type RequestFetchJabatansAction = {
  type: typeof REQUEST_FETCH_JABATANS
}

export type RequestCreateJabatanAction = {
  type: typeof REQUEST_CREATE_JABATAN
}

export type RequestUpdateJabatanAction = {
  type: typeof REQUEST_UPDATE_JABATAN
}

export type RequestDeleteJabatanAction = {
  type: typeof REQUEST_DELETE_JABATAN
}

export type RequestJabatanErrorAction = {
  type: typeof REQUEST_JABATAN_ERROR
  payload: string
}

export type SetJabatansAction = {
  type: typeof SET_JABATANS
  payload: Jabatan[]
}

export type ClearJabatanAction = {
  type: typeof CLEAR_JABATAN
}

export type ClearJabatanErrorAction = {
  type: typeof CLEAR_JABATAN_ERROR
}

export type JabatanActions =
  RequestFetchJabatansAction |
  RequestCreateJabatanAction |
  RequestUpdateJabatanAction |
  RequestDeleteJabatanAction |
  RequestJabatanErrorAction |
  SetJabatansAction |
  ClearJabatanAction |
  ClearJabatanErrorAction
