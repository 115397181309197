import {
  SuratMasukState,
  SuratMasukActions,
  REQUEST_FETCH_SURAT_MASUKS,
  REQUEST_CREATE_SURAT_MASUK,
  REQUEST_UPDATE_SURAT_MASUK,
  REQUEST_DELETE_SURAT_MASUK,
  REQUEST_SURAT_MASUK_ERROR,
  SET_SURAT_MASUKS,
  CLEAR_SURAT_MASUK,
  CLEAR_SURAT_MASUK_ERROR
} from '../types/surat-masuk'

const initialState: SuratMasukState = {
  suratMasuks: [],
  loading: false,
  error: null
}

export default function (state = initialState, action: SuratMasukActions): SuratMasukState {

  switch (action.type) {

    case REQUEST_FETCH_SURAT_MASUKS:
    case REQUEST_CREATE_SURAT_MASUK:
    case REQUEST_UPDATE_SURAT_MASUK:
    case REQUEST_DELETE_SURAT_MASUK:
      return {
        ...state,
        loading: true,
        error: null
      }

    case REQUEST_SURAT_MASUK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case SET_SURAT_MASUKS:
      return {
        ...state,
        loading: false,
        error: null,
        suratMasuks: action.payload
      }

    case CLEAR_SURAT_MASUK:
      return initialState

    case CLEAR_SURAT_MASUK_ERROR:
      return {
        ...state,
        loading: false,
        error: null
      }

    default:
      return state

  }

}
