import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import {
  useTheme,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import required from '../utils/required'
import { RootStates } from '../store/reducers'
import { ThunkDispatchType } from '../store/actions/_thunk'
import {
  createDisposisi,
  updateDisposisi
} from '../store/actions/disposisi'
import { fetchUsers } from '../store/actions/user'
import { ID } from '../graphql'
import {
  Disposisi,
  DisposisiInput,
  DisposisiUpdateInput
} from '../graphql/disposisi'

const mapStateToProps = ({ disposisi, user }: RootStates) => ({ disposisi, user })
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  fetchUsers: () => dispatch(fetchUsers()),
  createDisposisi: (data: DisposisiInput) => dispatch(createDisposisi(data)),
  updateDisposisi: (_id: ID, data: DisposisiUpdateInput) => dispatch(updateDisposisi(_id, data))
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  {
    suratMasukId: ID
    open: boolean
    setOpen: (open: boolean) => void
    data?: Disposisi
  }

type AutocompleteType = {
  _id: ID
  nama: string
  email: string
  nip?: string
  jabatan: string
}

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff'
    }
  })
))

const defaultInput: (surat_masuk: ID) => DisposisiInput = (surat_masuk) => ({
  surat_masuk,
  catatan: '',
  perintah: '',
  users: []
})

const initialInput: (disposisi: DisposisiInput) => DisposisiInput = (disposisi) => ({
  surat_masuk: disposisi.surat_masuk,
  catatan: disposisi.catatan,
  perintah: disposisi.perintah,
  users: disposisi.users
})

const PimpinanDisposisiDialog: React.FC<Props> = (props) => {
  const {
    data,
    suratMasukId,
    open,
    setOpen,
    disposisi,
    user,
    fetchUsers,
    createDisposisi,
    updateDisposisi
  } = props
  const { loading: disposisiLoading } = disposisi
  const { loading: userLoading } = user
  const loading = userLoading || disposisiLoading

  const [input, setInput] = useState<DisposisiInput>(defaultInput(suratMasukId))
  const [disposisiId, setDisposisiId] = useState<string>('')
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value
    })
  }

  const refSetOpen = useRef(setOpen)

  useEffect(() => {
    setInput(initialInput(
      data
        ? {
            surat_masuk: '',
            catatan: data.catatan,
            perintah: data.perintah,
            users: data.users.map(({ _id }) => _id)
          }
        : defaultInput(suratMasukId)
    ))
    setDisposisiId(
      data
        ? data._id
        : ''
    )
  }, [suratMasukId, data])

  useEffect(() => {
    refSetOpen.current(false)
  }, [disposisi.disposisis])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  const classes = useStyles()
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))

  const usersSelect: AutocompleteType[] = user.users.map(user => ({
    _id: user._id,
    nama: user.nama,
    email: user.email,
    nip: user.nip,
    jabatan: user.jabatan.nama
  }))
  const defaultValue = usersSelect.filter(({ _id }) => (
    input.users.includes(_id)
  ))

  const onSubmit = {
    create: () => {
      const data = input
      const validation = required('perintah|users', data)
      if (validation.error) {
        alert(validation.message)
      } else
        createDisposisi(data)
    },
    update: () => {
      const data: DisposisiUpdateInput = {
        catatan: input.catatan,
        perintah: input.perintah,
        users: input.users
      }
      const validation = required('perintah|users', data)
      if (validation.error) {
        alert(validation.message)
      } else
        updateDisposisi(disposisiId, data)
    }
  }

  return (
    <React.Fragment>
      <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={open}
        fullScreen={fullscreen}
        onClose={() => setOpen(false)}>
        <DialogTitle>
          Disposisi
          <Typography
            variant='body2'
            color='textSecondary'>
            {
              data
                ? 'Mengubah data disposisi dapat me-reset semua tanggapan pegawai'
                : 'Disposisikan surat kepada beberapa pegawai'
            }
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={e => e.preventDefault()}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  color='secondary'
                  name='perintah'
                  label='Perintah'
                  variant='outlined'
                  value={input.perintah}
                  onChange={onChangeInput}/>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  color='secondary'
                  name='catatan'
                  label='Catatan'
                  variant='outlined'
                  value={input.catatan}
                  onChange={onChangeInput}/>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={usersSelect}
                  value={defaultValue}
                  onChange={(_, value) => setInput({
                    ...input,
                    users: value.map(({ _id }) => _id)
                  })}
                  getOptionLabel={option => option.nama + ' - ' + (option.nip || '')}
                  renderInput={params => (
                    <TextField
                      {...params}
                      color='secondary'
                      variant='outlined'
                      label='Pilih Pegawai'
                      placeholder='Pegawai'/>
                  )}/>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={() => setOpen(false)}>Close</Button>
          <Button color='secondary' onClick={() => onSubmit[data ? 'update': 'create']()}>Save</Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={loading && open} className={classes.backdrop}>
        <CircularProgress color='inherit'/>
      </Backdrop>
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PimpinanDisposisiDialog)
