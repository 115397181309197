import { ThunkActionType } from './_thunk'
import { gqlRequest, GraphQLResponse } from './_graphql'
import {
  REQUEST_FETCH_JABATANS,
  REQUEST_CREATE_JABATAN,
  REQUEST_UPDATE_JABATAN,
  REQUEST_DELETE_JABATAN,
  REQUEST_JABATAN_ERROR,
  SET_JABATANS,
  CLEAR_JABATAN,
  CLEAR_JABATAN_ERROR
} from '../types/jabatan'
import { ID } from '../../graphql'
import { Jabatan, JabatanInput } from '../../graphql/jabatan'

const FETCH_JABATANS = () => ({
  query: `
    query { jabatans {
      _id nama role { _id nama level }
      users { _id }
      createdAt updatedAt
    } }
  `
})
const CREATE_JABATAN = (data: JabatanInput) => ({
  query: `
    mutation createJabatan ($data: JabatanInput!) {
      createJabatan (data: $data) { _id }
    }
  `,
  variables: { data }
})
const UPDATE_JABATAN = (_id: ID, data: JabatanInput) => ({
  query: `
    mutation updateJabatan ($_id: ID! $data: JabatanInput!) {
      updateJabatan (_id: $_id data: $data) { _id }
    }
  `,
  variables: { _id, data }
})
const DELETE_JABATAN = (_id: ID) => ({
  query: `
    mutation deleteJabatan ($_id: ID!) {
      deleteJabatan (_id: $_id) { _id }
    }
  `,
  variables: { _id }
})

export function fetchJabatans (): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_FETCH_JABATANS })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      const response = await gqlRequest<GraphQLResponse<{jabatans: Jabatan[]}>>(FETCH_JABATANS(), auth)
      dispatch({
        type: SET_JABATANS,
        payload: response.jabatans
      })
    } catch (err) {
      dispatch({
        type: REQUEST_JABATAN_ERROR,
        payload: err.message
      })
    }
  }
}

export function createJabatan (data: JabatanInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_CREATE_JABATAN })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{createJabatan: Jabatan}>>(CREATE_JABATAN(data), auth)
      await fetchJabatans()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_JABATAN_ERROR,
        payload: err.message
      })
    }
  }
}

export function updateJabatan (_id: ID, data: JabatanInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_UPDATE_JABATAN })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{updateJabatan: Jabatan}>>(UPDATE_JABATAN(_id, data), auth)
      await fetchJabatans()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_JABATAN_ERROR,
        payload: err.message
      })
    }
  }
}

export function deleteJabatan (_id: ID): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_JABATAN })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{deleteJabatan: Jabatan}>>(DELETE_JABATAN(_id), auth)
      await fetchJabatans()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_JABATAN_ERROR,
        payload: err.message
      })
    }
  }
}

export function clearJabatan (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_JABATAN })
  }
}

export function clearError (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_JABATAN_ERROR })
  }
}
