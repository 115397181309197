import { SuratMasuk as SuratMasukSchema } from '../../graphql/surat-masuk'

export const REQUEST_FETCH_SURAT_MASUKS = 'REQUEST_FETCH_SURAT_MASUKS'
export const REQUEST_CREATE_SURAT_MASUK = 'REQUEST_CREATE_SURAT_MASUK'
export const REQUEST_UPDATE_SURAT_MASUK = 'REQUEST_UPDATE_SURAT_MASUK'
export const REQUEST_DELETE_SURAT_MASUK = 'REQUEST_DELETE_SURAT_MASUK'
export const REQUEST_SURAT_MASUK_ERROR = 'REQUEST_SURAT_MASUK_ERROR'
export const SET_SURAT_MASUKS = 'SET_SURAT_MASUKS'
export const CLEAR_SURAT_MASUK = 'CLEAR_SURAT_MASUK'
export const CLEAR_SURAT_MASUK_ERROR = 'CLEAR_SURAT_MASUK_ERROR'

export type SuratMasuk = SuratMasukSchema

export type SuratMasukState = {
  suratMasuks: SuratMasuk[]
  loading: boolean
  error: string | null
}

export type RequestFetchSuratMasuksAction = {
  type: typeof REQUEST_FETCH_SURAT_MASUKS
}

export type RequestCreateSuratMasukAction = {
  type: typeof REQUEST_CREATE_SURAT_MASUK
}

export type RequestUpdateSuratMasukAction = {
  type: typeof REQUEST_UPDATE_SURAT_MASUK
}

export type RequestDeleteSuratMasukAction = {
  type: typeof REQUEST_DELETE_SURAT_MASUK
}

export type RequestSuratMasukErrorAction = {
  type: typeof REQUEST_SURAT_MASUK_ERROR
  payload: string
}

export type SetSuratMasuksAction = {
  type: typeof SET_SURAT_MASUKS
  payload: SuratMasuk[]
}

export type ClearSuratMasukAction = {
  type: typeof CLEAR_SURAT_MASUK
}

export type ClearSuratMasukErrorAction = {
  type: typeof CLEAR_SURAT_MASUK_ERROR
}

export type SuratMasukActions =
  RequestFetchSuratMasuksAction |
  RequestCreateSuratMasukAction |
  RequestUpdateSuratMasukAction |
  RequestDeleteSuratMasukAction |
  RequestSuratMasukErrorAction |
  SetSuratMasuksAction |
  ClearSuratMasukAction |
  ClearSuratMasukErrorAction
