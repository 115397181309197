import React from 'react'
import { connect } from 'react-redux'
import { MaterialTableProps } from 'material-table'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import PrintIcon from '@material-ui/icons/PrintOutlined'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

import { RootStates } from '../../../store/reducers'
import { ThunkDispatchType } from '../../../store/actions/_thunk'
import { PRINT_URI } from '../../../config'
import { ID } from '../../../graphql'
import { KebutuhanAcc } from '../../../graphql/kebutuhan-acc'
import {
  fetchSuratKeluars,
  accSuratKeluar,
  clearError
} from '../../../store/actions/surat-keluar'
import MainTitle from '../../../components/main-title'
import MySnackbar from '../../../components/snackbar'
import TableComponent from '../../../components/table'
import StatusBadge from '../../../components/status-badge'
import SuratKeluarDialog from '../../../components/surat-keluar-dialog'
import ConfirmDialog from '../../../components/confirm-dialog'

const mapStateToProps = ({ suratKeluar }: RootStates) => ({ suratKeluar })
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  fetchSuratKeluars: () => dispatch(fetchSuratKeluars()),
  accSuratKeluar: (_id: ID, status: number) => dispatch(accSuratKeluar(_id, status)),
  clearError: () => dispatch(clearError())
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type State = {
  modal: {
    open: boolean
    suratKeluarId?: ID
  }
  acc_confirm: {
    open: boolean
    title?: string
    content?: string
    onSubmit: () => void
  }
}

type Row = {
  _id: ID
  nomor: string
  lampiran?: number
  tujuan: string
  isi: string
  status: number
  arsip: string
  sifat: string
  kebutuhan_accs: KebutuhanAcc[]
}

type Table = MaterialTableProps<Row>

const initialState: State = {
  acc_confirm: {
    open: false,
    onSubmit: () => {}
  },
  modal: {
    open: false
  }
}

class AccSuratKeluarPage extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = initialState
    props.fetchSuratKeluars()
  }

  openSuratKeluarDialog = (suratKeluarId?: ID) => {
    const { modal } = this.state
    this.setState({
      modal: {
        ...modal,
        suratKeluarId, open: true
      }
    })
  }

  openConfirmDialog = (onSubmit: () => void, title?: string, content?: string) => {
    this.setState({
      acc_confirm: {
        open: true,
        onSubmit,
        title,
        content
      }
    })
  }

  render () {
    const { modal, acc_confirm } = this.state
    const { error, loading, suratKeluars } = this.props.suratKeluar
    const { clearError, accSuratKeluar } = this.props

    // mapping for data tables
    const mapSuratKeluars: Row[] = suratKeluars.map(suratKeluar => ({
      ...suratKeluar,
      sifat: suratKeluar.sifat._id,
      arsip: suratKeluar.arsip._id
    }))
    const arsips = suratKeluars.map(suratKeluar => ({
      ...suratKeluar.arsip
    }))
    const sifats = suratKeluars.map(suratKeluar => ({
      ...suratKeluar.sifat
    }))
    const columns: Table['columns'] = [
      {
        field: '_id',
        title: 'ID',
        hidden: true,
        editable: 'never'
      },
      {
        field: 'status',
        title: 'Status',
        editable: 'never',
        render: ({ status }) => <StatusBadge status={status}/>
      },
      {
        field: 'nomor',
        title: 'Nomor'
      },
      {
        field: 'tujuan',
        title: 'Tujuan'
      },
      {
        field: 'lampiran',
        title: 'Lampiran',
        render: ({ lampiran }) => lampiran ? `${lampiran} lembar` : '-'
      },
      {
        field: 'status',
        title: 'Cetak',
        editable: 'never',
        render: ({ status, _id }) => status !== 1
          ? null
          : (
              <Tooltip title='Print'>
                <IconButton href={`${PRINT_URI}?_id=${_id}`} target='_blank'>
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            )
      },
    ]
    const data: Table['data'] = mapSuratKeluars
    const actions: Table['actions'] = [
      {
        icon: () => <MoreIcon />,
        tooltip: 'More',
        onClick: (_e, rowData) => this.openSuratKeluarDialog((rowData as Row)._id)
      },
      {
        icon: () => <CheckIcon />,
        tooltip: 'Setujui',
        onClick: (_e, rowData) => (
          this.openConfirmDialog(
            () => accSuratKeluar((rowData as Row)._id, 1),
            'Setujui',
            'Apakah anda yakin ingin menyetujui surat ini?'
          )
        )
      },
      {
        icon: () => <CloseIcon />,
        tooltip: 'Tolak',
        onClick: (_e, rowData) => (
          this.openConfirmDialog(
            () => accSuratKeluar((rowData as Row)._id, -1),
            'Setujui',
            'Apakah anda yakin ingin menolak surat ini?'
          )
        )
      }
    ]

    return (
      <div>
        <MySnackbar
          open={error ? true : false}
          message={error}
          onClose={clearError} />
        <MainTitle title='Acc Surat Keluar'/>
        <TableComponent<Row>
          isLoading={loading}
          columns={columns}
          data={data}
          actions={actions}/>
        <SuratKeluarDialog
          readOnly={true}
          suratKeluarId={modal.suratKeluarId}
          open={modal.open}
          setOpen={(open) => this.setState({ modal: { ...modal, open } })}
          arsips={arsips}
          sifats={sifats}/>
        <ConfirmDialog
          open={acc_confirm.open}
          setOpen={open => this.setState({ acc_confirm: { ...acc_confirm, open } })}
          onSubmit={acc_confirm.onSubmit}
          title={acc_confirm.title}
          content={acc_confirm.content}
          />
      </div>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccSuratKeluarPage)
