import React from 'react'
import hdate from 'human-date'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListSubheader from '@material-ui/core/ListSubheader'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

import { SuratMasuk } from '../graphql/surat-masuk'
import avatarColor from '../utils/avatar-color'

type Props = {
  data?: SuratMasuk['disposisi']
  open: boolean
  setOpen: (status: Props['open']) => void
}

const DisposisiDialog: React.FC<Props> = (props) => {
  const { data, open, setOpen } = props

  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      maxWidth='sm'
      fullWidth={true}
      open={open}
      fullScreen={fullscreen}
      onClose={() => setOpen(false)}>
      <DialogTitle>Disposisi</DialogTitle>
      {
        data
          ? (
              <DialogContent style={{ padding: '0 8px' }}>
                <List>
                  <ListItem>
                    <ListItemText>
                      <ListItemText
                        primary='Perintah'
                        secondary={data.perintah}/>
                      <ListItemText
                        primary='Catatan'
                        secondary={data.catatan || '-'}/>
                      <ListItemText
                        primary='Didisposisikan pada'
                        secondary={hdate.relativeTime(new Date(data.createdAt))}/>
                    </ListItemText>
                  </ListItem>
                </List>
                <List style={{ backgroundColor: theme.palette.background.paper }}>
                  {
                    data.users.length <= 0
                      ? <ListSubheader>(tidak ada pegawai yang diatur sebagai penerima)</ListSubheader>
                      : <ListSubheader>Tanggapan</ListSubheader>
                  }
                  {
                    data.users.map((user,i) => (
                      <React.Fragment key={i}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar style={{
                              backgroundColor: avatarColor(user.email)}}>
                              {user.nama.split('')[0] || '?'}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={user.nama}
                            secondary={(
                              <React.Fragment>
                                {
                                  user.balasan
                                    ? (
                                        <React.Fragment>
                                          <Typography
                                            component='span'
                                            variant='body2'
                                            color='textPrimary'>{user.email}</Typography>
                                          {' — ' + (user.balasan || '')}
                                        </React.Fragment>
                                      )
                                    : '(belum ada tanggapan)'
                                }
                              </React.Fragment>
                            )}/>
                        </ListItem>
                        <Divider variant='inset' component='li' />
                      </React.Fragment>
                    ))
                  }
                </List>
              </DialogContent>
            )
          : <DialogContent>
              <DialogContentText>Surat belum terdisposisi</DialogContentText>
            </DialogContent>
      }
      <DialogActions>
        <Button color='secondary' onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DisposisiDialog
