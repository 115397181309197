import React from 'react'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'

type IProps = {
  title: string
}

const MainTitle: React.FC<IProps> = (props) => (
  <React.Fragment>
    <Hidden smDown>
      <Typography variant='h5' className='main-title'>{props.title}</Typography>
    </Hidden>
  </React.Fragment>
)

export default MainTitle
