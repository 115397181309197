import React from 'react'
import red from '@material-ui/core/colors/red'
import yellow from '@material-ui/core/colors/yellow'
import green from '@material-ui/core/colors/green'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import CheckIcon from '@material-ui/icons/CheckOutlined'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import PendingIcon from '@material-ui/icons/PriorityHighOutlined'

type Props = {
  status: number
}

const StatusBadge: React.FC<Props> = (props) => {
  const status: { [key: string]: any } = {
    '-1': <Chip
            avatar={(
              <Avatar style={{ backgroundColor: red['800'] }}>
                <CloseIcon style={{ color: 'white' }} fontSize='inherit'/>
              </Avatar>
            )}
            label='Ditolak'
            style={{
              backgroundColor: red['600'],
              color: 'white'
            }}/>,
    '0':  <Chip
            avatar={(
              <Avatar style={{ backgroundColor: yellow['700'] }}>
                <PendingIcon fontSize='inherit'/>
              </Avatar>
            )}
            label='Menunggu'
            style={{ backgroundColor: yellow['500'] }}/>,
    '1':  <Chip
            avatar={(
              <Avatar  style={{ backgroundColor: green['800'] }}>
                <CheckIcon style={{ color: 'white' }} fontSize='inherit'/>
              </Avatar>
            )}
            label='Disetujui'
            style={{
              backgroundColor: green['600'],
              color: 'white'
            }}/>
    }

  return (
    <React.Fragment>
      {status[props.status] || status[0]}
    </React.Fragment>
  )
}

export default StatusBadge
