import {
  SuratMasukDisposisiState,
  SuratMasukDisposisiActions,
  REQUEST_FETCH_SURAT_MASUK_DISPOSISIS,
  REQUEST_CREATE_DISPOSISI_RESPONSE,
  REQUEST_UPDATE_DISPOSISI_RESPONSE,
  REQUEST_SURAT_MASUK_DISPOSISI_ERROR,
  SET_SURAT_MASUK_DISPOSISIS,
  CLEAR_SURAT_MASUK_DISPOSISI,
  CLEAR_SURAT_MASUK_DISPOSISI_ERROR,
} from '../types/surat-masuk-disposisi'

const initialState: SuratMasukDisposisiState = {
  suratMasukDisposisis: [],
  loading: false,
  error: null
}

export default function (state = initialState, action: SuratMasukDisposisiActions): SuratMasukDisposisiState {

  switch (action.type) {

    case REQUEST_FETCH_SURAT_MASUK_DISPOSISIS:
    case REQUEST_CREATE_DISPOSISI_RESPONSE:
    case REQUEST_UPDATE_DISPOSISI_RESPONSE:
      return {
        ...state,
        loading: true,
        error: null
      }

    case REQUEST_SURAT_MASUK_DISPOSISI_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case SET_SURAT_MASUK_DISPOSISIS:
      return {
        ...state,
        suratMasukDisposisis: action.payload,
        loading: false,
        error: null
      }

    case CLEAR_SURAT_MASUK_DISPOSISI:
      return initialState

    case CLEAR_SURAT_MASUK_DISPOSISI_ERROR:
      return {
        ...state,
        loading: false,
        error: null
      }

    default:
      return state

  }

}
