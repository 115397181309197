import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { MaterialTableProps } from 'material-table'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DownloadIcon from '@material-ui/icons/GetAppOutlined'
import DisposisiIcon from '@material-ui/icons/TrendingDownOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility'

import { RootStates } from '../../../store/reducers'
import { ThunkDispatchType } from '../../../store/actions/_thunk'
import { STORAGE_URI } from '../../../config'
import { ID } from '../../../graphql'
import { SuratMasuk } from '../../../graphql/surat-masuk'
import { clearError as suratMasukClearError } from '../../../store/actions/surat-masuk'
import {
  clearError as userClearError
} from '../../../store/actions/user'
import {
  clearError as disposisiClearError
} from '../../../store/actions/disposisi'
import { setDisposisiTemp, setSuratMasukTemp } from '../../../store/actions/temp'
import { fetchSuratMasuks } from '../../../store/actions/surat-masuk'
import MainTitle from '../../../components/main-title'
import MySnackbar from '../../../components/snackbar'
import TableComponent from '../../../components/table'
import PimpinanDisposisiDialog from '../../../components/pimpinan-disposisi-dialog'

const mapStateToProps = ({ suratMasuk, user, disposisi, temp }: RootStates) => ({
  suratMasuk, user, disposisi, temp
})
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  fetchSuratMasuks: () => dispatch(fetchSuratMasuks()),
  setDisposisiTemp: (_id?: ID) => dispatch(setDisposisiTemp(_id)),
  setSuratMasukTemp: (_id?: ID) => dispatch(setSuratMasukTemp(_id)),
  clearError: () => {
    dispatch(suratMasukClearError())
    dispatch(userClearError())
    dispatch(disposisiClearError())
  }
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type State = {
  modal: {
    open: boolean
    suratMasukId: ID
  }
}

type Row = {
  _id: ID
  berkas: string
  nomor: string
  lampiran?: number
  sumber: string
  isi: string
  arsip: string
  sifat: string
  disposisi?: SuratMasuk['disposisi']
}

type Table = MaterialTableProps<Row>

class SuratMasukPage extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      modal: {
        open: false,
        suratMasukId: ''
      }
    }
    props.fetchSuratMasuks()
  }

  openCreateDisposisiDialog = (suratMasukId: ID) => {
    this.setState({
      modal: {
        suratMasukId,
        open: true
      }
    })
  }

  componentWillUnmount () {
    this.props.setSuratMasukTemp(undefined)
  }

  render () {
    const { modal } = this.state
    const { error: disposisiError } = this.props.disposisi
    const { error: suratMasukError, loading: suratMasukLoading, suratMasuks } = this.props.suratMasuk
    const { error: userError, loading: userLoading } = this.props.user

    const { surat_masuk: suratMasukTarget } = this.props.temp

    const loading = suratMasukLoading || userLoading
    const error = suratMasukError || userError || disposisiError
    const { clearError, setDisposisiTemp } = this.props

    // mapping for data tables
    const mapSuratMasuks: Row[] = suratMasuks.map(suratMasuk => ({
      ...suratMasuk,
      sifat: suratMasuk.sifat.nama,
      arsip: suratMasuk.arsip.nama
    }))

    const columns: Table['columns'] = [
      {
        field: 'disposisi',
        title: 'Disposisi',
        render: ({ _id, disposisi }) => (
          disposisi
            ? <Tooltip title='Show'>
                <Link className='link' to='/dashboard/disposisi'>
                    <IconButton onClick={() => setDisposisiTemp(disposisi._id)}>
                      <VisibilityIcon />
                    </IconButton>
                  </Link>
              </Tooltip>
            : <Tooltip title='Disposisikan'>
                <IconButton
                  onClick={() => this.openCreateDisposisiDialog(_id)}>
                  <DisposisiIcon />
                </IconButton>
              </Tooltip>
        )
      },
      {
        field: '_id',
        title: 'ID',
        hidden: !suratMasukTarget ? true : false,
        editable: 'never'
      },
      {
        field: 'nomor',
        title: 'Nomor'
      },
      {
        field: 'lampiran',
        title: 'Lampiran',
        type: 'numeric',
        hidden: true,
        render: rowData => rowData.lampiran ? `${rowData.lampiran} lembar` : '-'
      },
      {
        field: 'sumber',
        title: 'Sumber'
      },
      {
        field: 'isi',
        title: 'Isi'
      },
      {
        field: 'arsip',
        title: 'Arsip',
        hidden: true
      },
      {
        field: 'sifat',
        title: 'Sifat',
        hidden: true
      },
      {
        field: 'berkas',
        title: 'Berkas',
        render: rowData => (
          <Tooltip title='Download'>
            <IconButton href={`${STORAGE_URI}/${rowData.berkas}`} target='_blank'>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )
      }
    ]
    const data: Table['data'] = mapSuratMasuks

    return (
      <div>
        <MySnackbar
          open={error ? true : false}
          message={error}
          onClose={clearError} />
        <MainTitle title='Surat Masuk'/>
        <TableComponent<Row>
          isLoading={loading}
          columns={columns}
          data={data}
          options={{
            search: suratMasukTarget ? true : false,
            searchText: suratMasukTarget || ''
          }}/>
        <PimpinanDisposisiDialog
          open={modal.open}
          setOpen={open => this.setState({ modal: { ...modal, open } })}
          suratMasukId={modal.suratMasukId}/>
      </div>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuratMasukPage)
