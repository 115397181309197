import { ThunkActionType } from './_thunk'
import { gqlRequest, GraphQLResponse } from './_graphql'
import {
  LOGIN_ERROR,
  LOGIN_REQUEST,
  SET_SESSION,
  CLEAR_SESSION,
  CLEAR_SESSION_ERROR,
  CLEAR_SESSION_LOADING,
  SESSION_HAS_EXPIRED
} from '../types/session'
import { User } from '../../graphql/user'

const LOGIN_GQL = (email: string, password: string) => ({
  query: `
    query login ($email: String! $password: String!) {
      login (email: $email password: $password) {
        _id email nama nip balasan
        auth { token token_type expires }
        jabatan { nama role { nama level } }
        createdAt updatedAt
      }
    }
  `,
  variables: { email, password }
})

export function login (email: string, password: string): ThunkActionType {
  return async dispatch => {
    dispatch({ type: LOGIN_REQUEST })
    try {
      const response = await gqlRequest<GraphQLResponse<{login: User}>>(LOGIN_GQL(email, password))
      dispatch({
        type: SET_SESSION,
        payload: response.login
      })
    } catch (err) {
      dispatch({
        type: LOGIN_ERROR,
        payload: err.message
      })
    }
  }
}

export function checkSessionExpiration (): ThunkActionType {
  return (dispatch, getState) => {
    const currentDate = new Date()
    const { expireAt } = getState().session
    if (expireAt && (currentDate >= new Date(expireAt))) {
      dispatch({
        type: SESSION_HAS_EXPIRED,
        payload: 'The session has expired'
      })
    }
  }
}

export function logout (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_SESSION })
  }
}

export function clearLoading (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_SESSION_LOADING })
  }
}

export function clearError (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_SESSION_ERROR })
  }
}
