import { Role as RoleSchema } from '../../graphql/role'

export const REQUEST_FETCH_ROLES = 'REQUEST_FETCH_ROLES'
export const REQUEST_ROLE_ERROR = 'REQUEST_ROLE_ERROR'
export const SET_ROLES = 'SET_ROLES'
export const CLEAR_ROLE = 'CLEAR_ROLE'
export const CLEAR_ROLE_ERROR = 'CLEAR_ROLE_ERROR'

export type Role = RoleSchema

export type RoleState = {
  roles: Role[]
  loading: boolean
  error: string | null
}

export type RequestFetchRoleAction = {
  type: typeof REQUEST_FETCH_ROLES
}

export type RequestRoleErrorAction = {
  type: typeof REQUEST_ROLE_ERROR
  payload: string
}

export type SetRolesAction = {
  type: typeof SET_ROLES
  payload: Role[]
}

export type ClearRoleAction = {
  type: typeof CLEAR_ROLE
}

export type ClearRoleErrorAction = {
  type: typeof CLEAR_ROLE_ERROR
}

export type RoleActions =
  RequestFetchRoleAction |
  RequestRoleErrorAction |
  SetRolesAction |
  ClearRoleAction |
  ClearRoleErrorAction
