import { RouteProps } from 'react-router-dom'
import { SvgIconComponent } from '@material-ui/icons'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import UserIcon from '@material-ui/icons/GroupOutlined'
import JabatanIcon from '@material-ui/icons/RecentActorsOutlined'
import SifatIcon from '@material-ui/icons/LabelOutlined'
import ArsipIcon from '@material-ui/icons/ArchiveOutlined'
import SuratKeluarIcon from '@material-ui/icons/DraftsOutlined'
import SuratMasukIcon from '@material-ui/icons/MailOutlined'
import DisposisiIcon from '@material-ui/icons/TrendingDownOutlined'
import AccSuratIcon from '@material-ui/icons/DoneAllOutlined'
import ReceiveDisposisiIcon from '@material-ui/icons/MarkunreadMailboxOutlined'

import {
  main as MainPage,
  user as UserPage,
  jabatan as JabatanPage,
  arsip as ArsipPage,
  sifat as SifatPage,
  suratMasuk as SuratMasukPage,
  suratKeluar as SuratKeluarPage,
  suratMasukPimpinan as SuratMasukPimpinanPage,
  disposisi as DisposisiPage,
  accSuratKeluar as AccSuratKeluarPage,
  receiveDisposisi as ReceiveDisposisiPage
} from '../pages/dashboard'

export type PrivateRouteProps = RouteProps & {
  path: string
  name: string
  icon: SvgIconComponent
  levels: number[]
  main?: boolean
  component: any
}

const pages: PrivateRouteProps[] = [
  {
    name: 'Beranda',
    icon: HomeIcon,
    path: '/main',
    component: MainPage,
    levels: [1,2,3,4],
    main: true
  },
  {
    name: 'Pengguna',
    icon: UserIcon,
    path: '/user',
    component: UserPage,
    levels: [1]
  },
  {
    name: 'Jabatan',
    icon: JabatanIcon,
    path: '/jabatan',
    component: JabatanPage,
    levels: [1]
  },
  {
    name: 'Arsip',
    icon: ArsipIcon,
    path: '/arsip',
    component: ArsipPage,
    levels: [2]
  },
  {
    name: 'Sifat Surat',
    icon: SifatIcon,
    path: '/sifat-surat',
    component: SifatPage,
    levels: [2]
  },
  {
    name: 'Surat Masuk',
    icon: SuratMasukIcon,
    path: '/surat-masuk',
    component: SuratMasukPage,
    levels: [2]
  },
  {
    name: 'Surat Keluar',
    icon: SuratKeluarIcon,
    path: '/surat-keluar',
    component: SuratKeluarPage,
    levels: [2]
  },
  {
    name: 'Surat Masuk',
    icon: SuratMasukIcon,
    path: '/surat-masuk',
    component: SuratMasukPimpinanPage,
    levels: [3]
  },
  {
    name: 'Disposisi',
    icon: DisposisiIcon,
    path: '/disposisi',
    component: DisposisiPage,
    levels: [3]
  },
  {
    name: 'Acc Surat keluar',
    icon: AccSuratIcon,
    path: '/acc-surat-keluar',
    component: AccSuratKeluarPage,
    levels: [3]
  },
  {
    name: 'Disposisi',
    icon: ReceiveDisposisiIcon,
    path: '/receive-disposisi',
    component: ReceiveDisposisiPage,
    levels: [4]
  }
]

const privatePages: (prefix: string, level?: number) => PrivateRouteProps[] = (prefix, level) => {
  return level
    ? pages
        .filter(page => page.levels.includes(level))
        .map(page => ({
          ...page,
          path: prefix + page.path
        }))
    : []
}

export default privatePages
