import { colors, Color } from '@material-ui/core'

export default function (str: string): string {
  const intOfStr = (str.charCodeAt(0) - 97) + (str.length)

  const colorLevel = '700'
  const allColors: string[] = Object
    .keys(colors)
    .reduce((state: string[], key) => {
      const curr: Color = (colors as any)[key]
      const currColor = curr[colorLevel]
      return currColor
        ? [ ...state, currColor ]
        : state
    }, [])

  let index = intOfStr % allColors.length
  index = index < 0
    ? index * -1
    : index

  return allColors[index] || allColors[0]
}
