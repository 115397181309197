import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { RootStates } from '../store/reducers'
import { ThunkDispatchType } from '../store/actions/_thunk'
import { login, clearError, clearLoading } from '../store/actions/session'
import MySnackbar from '../components/snackbar'
import AvatarImage from '../assets/img/avatar.jpg'

const mapStateToProps = ({ session }: RootStates) => ({ session })
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  login: (email: string, password: string) => dispatch(login(email, password)),
  clearLoading: () => dispatch(clearLoading()),
  clearError: () => dispatch(clearError())
})

type State = {
  input: {
    email: string
    password: string
  }
}

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

class LoginPage extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      input: {
        email: '',
        password: ''
      }
    }
    props.clearLoading()
  }

  clearError = () => {
    this.props.clearError()
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.setState({
      input: {
        ...this.state.input,
        [e.target.name]: e.target.value
      }
    })
  }

  handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const { email, password } = this.state.input
    this.props.login(email, password)
  }

  render () {
    const { handleInputChange, handleFormSubmit, clearError } = this
    const { input } = this.state
    const { loading, error, info } = this.props.session

    if (info) return ( <Redirect to='/dashboard/main'/> )

    return (
      <div className='login'>
        <MySnackbar
          open={error ? true : false}
          message={error}
          onClose={clearError} />
        <div className='content'>
          <Typography variant='h6' className='welcome'>
            Welcome
          </Typography>
          <Avatar
            src={AvatarImage}
            className='avatar' />
          <form onSubmit={handleFormSubmit}>
            <TextField
              className='input-field'
              name='email'
              color='secondary'
              label='Email'
              value={input.email}
              onChange={handleInputChange }
              fullWidth />
            <TextField
              className='input-field'
              name='password'
              type='password'
              color='secondary'
              label='Password'
              value={input.password}
              onChange={handleInputChange }
              fullWidth />
            <Button
              type='submit'
              disabled={loading}
              className='input-field'
              color='secondary'
              variant='contained'
              fullWidth >
              {
                loading
                  ? (<CircularProgress
                      color='secondary'
                      size={24}/>)
                  : 'Login'
              }
            </Button>
          </form>
        </div>
      </div>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage)
