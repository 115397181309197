import { ThunkActionType } from './_thunk'
import { gqlRequest, GraphQLResponse } from './_graphql'
import {
  REQUEST_FETCH_SURAT_MASUK_DISPOSISIS,
  REQUEST_CREATE_DISPOSISI_RESPONSE,
  REQUEST_UPDATE_DISPOSISI_RESPONSE,
  REQUEST_SURAT_MASUK_DISPOSISI_ERROR,
  SET_SURAT_MASUK_DISPOSISIS,
  CLEAR_SURAT_MASUK_DISPOSISI,
  CLEAR_SURAT_MASUK_DISPOSISI_ERROR,
} from '../types/surat-masuk-disposisi'
import { SuratMasukDisposisi } from '../../graphql/surat-masuk'
import { DisposisiResponseInput, DisposisiResponse } from '../../graphql/disposisi-response'

const FETCH_SURAT_MASUK_DISPOSISIS = () => ({
  query: `
    query {
      suratMasukDisposisis {
        _id catatan perintah surat_masuk {
          _id berkas nomor lampiran sumber isi status
          arsip { _id } sifat { _id } disposisi {
            _id users { _id balasan }
          }
        }
        createdAt updatedAt
      }
    }
  `
})
const CREATE_DISPOSISI_RESPONSE = (data: DisposisiResponseInput) => ({
  query: `
    mutation createBalasanDisposisi ($data: DisposisiResponseInput!) {
      createBalasanDisposisi (data: $data) {
        _id
      }
    }
  `,
  variables: { data }
})
const UPDATE_DISPOSISI_RESPONSE = (data: DisposisiResponseInput) => ({
  query: `
    mutation updateBalasanDisposisi ($data: DisposisiResponseInput!) {
      updateBalasanDisposisi (data: $data) {
        _id
      }
    }
  `,
  variables: { data }
})

export function fetchSuratMasukDisposisis (): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_FETCH_SURAT_MASUK_DISPOSISIS })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      const response = await gqlRequest<GraphQLResponse<{suratMasukDisposisis: SuratMasukDisposisi[]}>>
        (FETCH_SURAT_MASUK_DISPOSISIS(), auth)
      dispatch({
        type: SET_SURAT_MASUK_DISPOSISIS,
        payload: response.suratMasukDisposisis
      })
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_MASUK_DISPOSISI_ERROR,
        payload: err.message
      })
    }
  }
}

export function createDisposisiResponse (data: DisposisiResponseInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_CREATE_DISPOSISI_RESPONSE })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{createBalasanDisposisi: DisposisiResponse}>>(CREATE_DISPOSISI_RESPONSE(data), auth)
      await fetchSuratMasukDisposisis()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_MASUK_DISPOSISI_ERROR,
        payload: err.message
      })
    }
  }
}

export function updateDisposisiResponse (data: DisposisiResponseInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_UPDATE_DISPOSISI_RESPONSE })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{updateBalasanDisposisi: DisposisiResponse}>>(UPDATE_DISPOSISI_RESPONSE(data), auth)
      await fetchSuratMasukDisposisis()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_MASUK_DISPOSISI_ERROR,
        payload: err.message
      })
    }
  }
}

export function clearSuratMasukDisposisi (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_SURAT_MASUK_DISPOSISI })
  }
}

export function clearError (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_SURAT_MASUK_DISPOSISI_ERROR })
  }
}
