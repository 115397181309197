import React from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'

import { RootStates } from '../../store/reducers'
import MainTitle from '../../components/main-title'

const mapStateToProps = ({ session }: RootStates) => ({ session })

type Props = ReturnType<typeof mapStateToProps>

class MainPage extends React.Component<Props> {

  render () {
    const { info } = this.props.session

    return (
      <div className='profile'>
        <MainTitle title='Beranda'/>
        <div className='header'>
          <Typography variant='h5' className='title'>
            {info?.nama}
          </Typography>
          <Typography variant='body1' className='subtitle'>
            {info?.email}
          </Typography>
        </div>
        <div className='body'>
          <table>
            <tbody>
              <tr>
                <th>Nama</th>
                <td>{info?.nama}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  <a href={`mailto:${info?.email}`} target='_blank' rel='noopener noreferrer'>
                    {info?.email}
                  </a>
                </td>
              </tr>
              <tr>
                <th>NIP</th>
                <td>{info?.nip || '-'}</td>
              </tr>
              <tr>
                <th>Jabatan</th>
                <td><Chip
                  className='chip'
                  size='small'
                  label={info?.jabatan.nama}
                  avatar={(<Avatar>{info?.jabatan.role.level}</Avatar>)}
                  color='secondary'/></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}

export default connect(
  mapStateToProps
)(MainPage)
