import { SuratKeluar as SuratKeluarSchema } from '../../graphql/surat-keluar'

export const REQUEST_FETCH_SURAT_KELUARS = 'REQUEST_FETCH_SURAT_KELUARS'
export const REQUEST_CREATE_SURAT_KELUAR = 'REQUEST_CREATE_SURAT_KELUAR'
export const REQUEST_UPDATE_SURAT_KELUAR = 'REQUEST_UPDATE_SURAT_KELUAR'
export const REQUEST_DELETE_SURAT_KELUAR = 'REQUEST_DELETE_SURAT_KELUAR'
export const REQUEST_ACC_SURAT_KELUAR = 'REQUEST_ACC_SURAT_KELUAR'
export const REQUEST_SURAT_KELUAR_ERROR = 'REQUEST_SURAT_KELUAR_ERROR'
export const SET_SURAT_KELUARS = 'SET_SURAT_KELUARS'
export const CLEAR_SURAT_KELUAR = 'CLEAR_SURAT_KELUAR'
export const CLEAR_SURAT_KELUAR_ERROR = 'CLEAR_SURAT_KELUAR_ERROR'

export type SuratKeluar = SuratKeluarSchema

export type SuratKeluarState = {
  suratKeluars: SuratKeluar[]
  loading: boolean
  error: string | null
}

export type RequestFetchSuratKeluarsAction = {
  type: typeof REQUEST_FETCH_SURAT_KELUARS
}

export type RequestCreateSuratKeluarAction = {
  type: typeof REQUEST_CREATE_SURAT_KELUAR
}

export type RequestUpdateSuratKeluarAction = {
  type: typeof REQUEST_UPDATE_SURAT_KELUAR
}

export type RequestDeleteSuratKeluarAction = {
  type: typeof REQUEST_DELETE_SURAT_KELUAR
}

export type RequestAccSuratKeluarAction = {
  type: typeof REQUEST_ACC_SURAT_KELUAR
}

export type RequestSuratKeluarErrorAction = {
  type: typeof REQUEST_SURAT_KELUAR_ERROR
  payload: string
}

export type SetSuratKeluarsAction = {
  type: typeof SET_SURAT_KELUARS
  payload: SuratKeluar[]
}

export type ClearSuratKeluarAction = {
  type: typeof CLEAR_SURAT_KELUAR
}

export type ClearSuratKeluarErrorAction = {
  type: typeof CLEAR_SURAT_KELUAR_ERROR
}

export type SuratKeluarActions =
  RequestFetchSuratKeluarsAction |
  RequestCreateSuratKeluarAction |
  RequestUpdateSuratKeluarAction |
  RequestDeleteSuratKeluarAction |
  RequestAccSuratKeluarAction |
  RequestSuratKeluarErrorAction |
  SetSuratKeluarsAction |
  ClearSuratKeluarAction |
  ClearSuratKeluarErrorAction
