import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  title?: string
  content?: string
  onSubmit: () => void
}

const ConfirmDialog: React.FC<Props> = (props) => {
  const {
    open,
    setOpen,
    title,
    content,
    onSubmit
  } = props

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color='secondary'>
          Cancel
        </Button>
        <Button onClick={() => {
          onSubmit()
          setOpen(false)
        }} color='secondary' autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
