import React from 'react'
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

type IProps = SnackbarProps & {
  onClose: (event: React.SyntheticEvent<any>, reason: string) => void
}

const CustomSnackbar: React.FC<IProps> = (props) => {
  return (
    <Snackbar
      {...props}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      action={(
        <React.Fragment>
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={e => props.onClose(e, 'clickaway')} >
            <CloseIcon fontSize='small' />
          </IconButton>
        </React.Fragment>
      )}
      />
  )
}

CustomSnackbar.defaultProps = {
  autoHideDuration: 3000,
  onClose: () => null
}

export default CustomSnackbar
