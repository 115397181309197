import axios, { AxiosRequestConfig } from 'axios'
import { GraphQLError } from 'graphql'
import { API_URI } from '../../config'

export type GraphQLResponse<T = any> = {
  data: T
  errors?: Readonly<GraphQLError>[]
}

export type GraphQLDataRequest = {
  query: string
  variables?: { [name: string]: any }
}

export type GraphQLFileDataRequest = GraphQLDataRequest & {
  variables: { [name: string]: any }
}

export async function gqlRequest <T extends GraphQLResponse<any>> (data: GraphQLDataRequest, auth?: string): Promise<T['data']> {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: auth }
    }

    const response = await axios.post<T>(API_URI, data, config)
    if (response.data.errors)
      throw new Error(response.data.errors[0].message)
    return response.data.data
  } catch (err) {
    throw new Error(err.message || 'Something went wrong')
  }
}

export async function gqlFileRequest <T extends GraphQLResponse<any>> (data: GraphQLFileDataRequest, auth?: string, fileKey = 'file'): Promise<T['data']> {
  try {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: auth,
        'Content-Type': 'multipart/form-data'
      }
    }

    const keys = fileKey.split('.')
    const varFile: any = keys.reduce((state, val) => state[val], data.variables)

    // create form data request
    const reqData = new FormData()
    reqData.append('operations', JSON.stringify(data))
    reqData.append('map', JSON.stringify({ [fileKey]: [`variables.${fileKey}`] }))
    reqData.append(fileKey, varFile)

    const response = await axios.post<T>(API_URI, reqData, config)
    if (response.data.errors)
      throw new Error(response.data.errors[0].message)
    return response.data.data
  } catch (err) {
    throw new Error(err.message || 'Something went wrong')
  }
}
