import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import required from '../utils/required'
import { ID } from '../graphql'
import { DisposisiResponseInput } from '../graphql/disposisi-response'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  onSubmit: (data: DisposisiResponseInput) => void
  data: {
    disposisi: ID
    balasan: string | null
  }
}

const initialInput: Props['data'] = {
  disposisi: '',
  balasan: null
}

const ReplyDisposisiDialog: React.FC<Props> = (props) => {
  const {
    open,
    setOpen,
    onSubmit,
    data
  } = props

  const [input, setInput] = useState<Props['data']>(initialInput)

  useEffect(() => {
    setInput(data)
  }, [data])

  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Dialog
      maxWidth='sm'
      fullWidth={true}
      open={open}
      fullScreen={fullscreen}
      onClose={() => setOpen(false)}>
      <DialogTitle>Balasan</DialogTitle>
      <DialogContent>
        <form onSubmit={e => e.preventDefault()}>
          <TextField
            fullWidth
            name='balasan'
            label='Balasan'
            color='secondary'
            variant='outlined'
            value={input.balasan || ''}
            onChange={e => setInput({ ...input, [e.target.name]: e.target.value })} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color='secondary'>
          Cancel
        </Button>
        <Button onClick={() => {
          const data: DisposisiResponseInput = {
            disposisi: input.disposisi,
            balasan: input.balasan ? input.balasan : ''
          }
          const validation = required('disposisi|balasan', data)
          if (validation.error)
            alert(validation.message)
          else {
            onSubmit(data)
            setOpen(false)
          }
        }} color='secondary' autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReplyDisposisiDialog
