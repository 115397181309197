import React from 'react'
import { connect } from 'react-redux'
import { MaterialTableProps } from 'material-table'
import Typography from '@material-ui/core/Typography'
import ReplyIcon from '@material-ui/icons/Reply'
import DownloadIcon from '@material-ui/icons/GetAppOutlined'

import { RootStates } from '../../../store/reducers'
import { ThunkDispatchType } from '../../../store/actions/_thunk'
import { DisposisiResponseInput } from '../../../graphql/disposisi-response'
import { STORAGE_URI } from '../../../config'
import { ID } from '../../../graphql'
import {
  fetchSuratMasukDisposisis,
  createDisposisiResponse,
  updateDisposisiResponse,
  clearError
} from '../../../store/actions/surat-masuk-disposisi'
import MainTitle from '../../../components/main-title'
import MySnackbar from '../../../components/snackbar'
import TableComponent from '../../../components/table'
import ReplyDisposisiDialog from '../../../components/reply-disposisi-dialog'

const mapStateToProps = ({ session, suratMasukDisposisi }: RootStates) => ({ session, suratMasukDisposisi })
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  fetchSuratMasukDisposisis: () => dispatch(fetchSuratMasukDisposisis()),
  createDisposisiResponse: (data: DisposisiResponseInput) => dispatch(createDisposisiResponse(data)),
  updateDisposisiResponse: (data: DisposisiResponseInput) => dispatch(updateDisposisiResponse(data)),
  clearError: () => dispatch(clearError())
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type State = {
  reply: {
    open: boolean
    onSubmit: (data: DisposisiResponseInput) => void
    data: {
      disposisi: ID
      balasan: string | null
    }
  }
}

type Row = {
  _id: ID
  perintah: string
  catatan: string
  balasan?: string
  surat_masuk: string
}

type Table = MaterialTableProps<Row>

class ReceiveDisposisiPage extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      reply: {
        open: false,
        onSubmit: () => {},
        data: {
          disposisi: '',
          balasan: null
        }
      }
    }
    props.fetchSuratMasukDisposisis()
  }

  openReplyModal = (data: State['reply']['data'], onSubmit: State['reply']['onSubmit']) => {
    this.setState({
      reply: { open: true, onSubmit, data }
    })
  }

  render () {
    const { reply } = this.state
    const { info } = this.props.session
    const { loading, error, suratMasukDisposisis } = this.props.suratMasukDisposisi
    const {
      createDisposisiResponse,
      updateDisposisiResponse,
      clearError
    } = this.props

    const sessionUserId = info?._id

    const data: Table['data'] = suratMasukDisposisis.map(data => ({
      ...data,
      surat_masuk: data.surat_masuk.berkas,
      balasan: data.surat_masuk.disposisi.users
        .find(({ _id }) => _id === sessionUserId)
        ?.balasan
    }))
    const columns: Table['columns'] = [
      {
        field: '_id',
        title: 'ID',
        hidden: true,
        editable: 'never'
      },
      {
        field: 'perintah',
        title: 'Perintah'
      },
      {
        field: 'catatan',
        title: 'Catatan',
        render: rowData => rowData.catatan || '-'
      },
      {
        field: 'balasan',
        title: 'Balasan',
        render: rowData => (
          rowData.balasan !== null
            ? rowData.balasan
            : (
                <Typography variant='body2' color='textSecondary'>
                  (belum terbalas)
                </Typography>
              )
        )
      }
    ]
    const actions: Table['actions'] = [
      {
        icon: () => <ReplyIcon />,
        tooltip: 'Reply',
        onClick: (_, rowData) => {
          const data = rowData as Row
          const { balasan = null } = data
          this.openReplyModal(
            { disposisi: data._id, balasan },
            balasan === null
              ? createDisposisiResponse
              : updateDisposisiResponse
          )
        }
      },
      {
        icon: () => <DownloadIcon />,
        tooltip: 'Download Surat',
        onClick: (_, rowData) => window.open(
          `${STORAGE_URI}/${(rowData as Row).surat_masuk}`,
          '_blank'
        )
      }
    ]

    return (
      <div>
        <MySnackbar
          open={error ? true : false}
          message={error}
          onClose={clearError} />
        <MainTitle title='Disposisi'/>
        <TableComponent<Row>
          isLoading={loading}
          columns={columns}
          data={data}
          actions={actions}/>
        <ReplyDisposisiDialog
          data={reply.data}
          open={reply.open}
          onSubmit={reply.onSubmit}
          setOpen={open => this.setState({ reply: { ...reply, open } })}/>
      </div>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiveDisposisiPage)
