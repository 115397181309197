import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Link,
  withRouter,
  RouteComponentProps,
  LinkProps
} from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import MenuIcon from '@material-ui/icons/Menu'
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined'

import {
  checkSessionExpiration,
  logout
} from '../store/actions/session'
import { PrivateRouteProps } from '../routes/private'
import AvatarImage from '../assets/img/avatar.jpg'
import { RootStates } from '../store/reducers'
import { ThunkDispatchType } from '../store/actions/_thunk'

const mapStateToProps = ({ session }: RootStates) => ({ session })
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  logout: () => dispatch(logout()),
  checkSessionExpiration: () => dispatch(checkSessionExpiration())
})

type RoutesAsProps = { routes: PrivateRouteProps[] }
type CustomDrawerProps =
  RoutesAsProps &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>
type DrawerContentProps = RoutesAsProps & {
  currentRoute?: PrivateRouteProps
  toggleDrawer: (status: boolean) => void
  session: ReturnType<typeof mapStateToProps>['session']
  logout: ReturnType<typeof mapDispatchToProps>['logout']
}

const DrawerContent: React.FC<DrawerContentProps> = (props) => {
  const {
    routes,
    currentRoute,
    toggleDrawer,
    session,
    logout
  } = props

  const cuurentPath = currentRoute
    ? currentRoute.path
    : ''
  const mainRoute = routes.find(route => route.main) || routes[0]
  const LinkWithCloseDrawer: React.FC<LinkProps> = (props) => {
    return (
      <Link
        onClick={() => toggleDrawer(false)}
        {...props}/>
    )
  }

  return (
    <React.Fragment>
      <div className='profile'>
        <LinkWithCloseDrawer to={mainRoute.path} className='link'>
          <Avatar src={AvatarImage} className='avatar'/>
        </LinkWithCloseDrawer>
        <Chip
          className='chip'
          size='small'
          label={session.info?.jabatan.nama}
          avatar={(<Avatar>{session.info?.jabatan.role.level}</Avatar>)}
          color='secondary'/>
        <LinkWithCloseDrawer to={mainRoute.path} className='link'>
          <div className='info'>
            <div className='title'>{session.info?.nama}</div>
            <div className='subtitle'>{session.info?.email}</div>
          </div>
        </LinkWithCloseDrawer>
      </div>
      <div className='lists'>
          <List component='div'>
            {
              routes.map((route: any,i) => (
                <LinkWithCloseDrawer to={route.path} className='link' key={i}>
                  <ListItem
                    button
                    className='list_item'
                    selected={cuurentPath === route.path}>
                    <ListItemIcon><route.icon/></ListItemIcon>
                    <ListItemText>{route.name}</ListItemText>
                  </ListItem>
                </LinkWithCloseDrawer>
              ))
            }
          </List>
          <Divider />
          <List component='div'>
            <ListItem
              button
              onClick={() => logout()}
              className='list_item' >
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </ListItem>
          </List>
        </div>
    </React.Fragment>
  )
}

const CustomDrawer: React.FC<CustomDrawerProps> = (props) => {
  const { routes, location, session, logout, checkSessionExpiration } = props
  const [open, toggle] = useState(false)
  checkSessionExpiration()

  const currentRoute = routes.find(route => route.path === location.pathname)

  return (
    <React.Fragment>
      {/* Drawer for desktop */}
      <Hidden smDown>
        <Drawer
          open
          variant='permanent'
          className='drawer'
          classes={{
            paper: 'paper'
          }} >
          <DrawerContent
            session={session}
            logout={logout}
            routes={routes}
            currentRoute={currentRoute}
            toggleDrawer={() => toggle(false)}/>
        </Drawer>
      </Hidden>

      {/* Drawer for mobile */}
      <Hidden mdUp>
        <AppBar color='secondary' className='appbar'>
          <Toolbar>
            <IconButton
              className='icon'
              edge='start'
              color='inherit'
              aria-label='Menu'
              onClick={() => toggle(!open)} >
              <MenuIcon />
            </IconButton>
            <Typography
              className='text'
              variant='h6'>
              {currentRoute?.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          variant='temporary'
          className='drawer'
          classes={{
            paper: 'paper'
          }}
          open={open}
          onClose={() => toggle(false)}
          onOpen={() => toggle(true)} >
          <DrawerContent
            session={session}
            logout={logout}
            routes={routes}
            currentRoute={currentRoute}
            toggleDrawer={() => toggle(false)}/>
        </SwipeableDrawer>
      </Hidden>
    </React.Fragment>
  )
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CustomDrawer) as React.ComponentType<RoutesAsProps>
