import { ID } from '../../graphql'

export const SET_SURAT_MASUK_TEMP = 'SET_SURAT_MASUK_TEMP'
export const SET_DISPOSISI_TEMP = 'SET_DISPOSISI_TEMP'
export const RESET_TEMP = 'RESET_TEMP'

export type TempState = {
  surat_masuk?: ID
  disposisi?: ID
}

export type SetSuratMasukTempAction = {
  type: typeof SET_SURAT_MASUK_TEMP
  payload?: ID
}

export type SetDisposisiTempAction = {
  type: typeof SET_DISPOSISI_TEMP
  payload?: ID
}

export type ResetTempAction = {
  type: typeof RESET_TEMP
}

export type TempActions =
  SetSuratMasukTempAction |
  SetDisposisiTempAction |
  ResetTempAction
