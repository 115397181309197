import {
  SET_SESSION,
  CLEAR_SESSION,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  CLEAR_SESSION_ERROR,
  CLEAR_SESSION_LOADING,
  SESSION_HAS_EXPIRED,
  SessionActions,
  SessionState
} from '../types/session'

const initialState: SessionState = {
  info: null,
  loading: false,
  error: null,
  expireAt: null
}

export default function (state = initialState, action: SessionActions): SessionState {

  switch (action.type) {

    case LOGIN_REQUEST:
      return {
        ...state,
        info: null,
        loading: true,
        error: null
      }

    case LOGIN_ERROR:
      return {
        ...state,
        info: null,
        loading: false,
        error: action.payload
      }

    case SET_SESSION:
      const expireAt = new Date()
      expireAt.setSeconds(expireAt.getSeconds() + action.payload.auth.expires)

      return {
        ...state,
        info: action.payload,
        loading: false,
        error: null,
        expireAt
      }

    case CLEAR_SESSION_ERROR:
      return {
        ...state,
        loading: false,
        error: null
      }

    case CLEAR_SESSION_LOADING:
      return {
        ...state,
        loading: false
      }

    case CLEAR_SESSION:
      return initialState

    case SESSION_HAS_EXPIRED:
      return {
        ...initialState,
        error: action.payload
      }

    default:
      return state

  }

}
