import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { MaterialTableProps } from 'material-table'
import EditIcon from '@material-ui/icons/Edit'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import MoreIcon from '@material-ui/icons/MoreHoriz'

import { RootStates } from '../../../store/reducers'
import { ThunkDispatchType } from '../../../store/actions/_thunk'
import { ID } from '../../../graphql'
import { Disposisi, DisposisiUpdateInput } from '../../../graphql/disposisi'
import MainTitle from '../../../components/main-title'
import MySnackbar from '../../../components/snackbar'
import TableComponent from '../../../components/table'
import DisposisiDialog from '../../../components/disposisi-dialog'
import PimpinanDisposisiDialog from '../../../components/pimpinan-disposisi-dialog'
import {
  fetchDisposisis,
  updateDisposisi,
  deleteDisposisi,
  clearError
} from '../../../store/actions/disposisi'
import { setSuratMasukTemp, setDisposisiTemp } from '../../../store/actions/temp'

const mapStateToProps = ({ disposisi, temp }: RootStates) => ({ disposisi, temp })
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  fetchDisposisi: () => dispatch(fetchDisposisis()),
  updateDisposisi: (_id: ID, data: DisposisiUpdateInput) => dispatch(
    updateDisposisi(_id, data)
  ),
  deleteDisposisi: (_id: ID) => dispatch(deleteDisposisi(_id)),
  setSuratMasukTemp: (_id?: ID) => dispatch(setSuratMasukTemp(_id)),
  setDisposisiTemp: (_id?: ID) => dispatch(setDisposisiTemp(_id)),
  clearError: () => dispatch(clearError())
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type Row = Disposisi

type State = {
  response: {
    open: boolean
    data?: Disposisi
  }
  disposisi: {
    open: boolean
    data?: Disposisi
  }
}

type Table = MaterialTableProps<Row>

class DisposisiPage extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      response: {
        open: false
      },
      disposisi: {
        open: false
      }
    }
    props.fetchDisposisi()
  }

  openResponseDialog = (data?: Disposisi) => {
    this.setState({
      response: { open: true, data }
    })
  }

  openDisposisiDialog = (data?: Disposisi) => {
    this.setState({
      disposisi: { open: true, data }
    })
  }

  componentWillUnmount () {
    this.props.setDisposisiTemp(undefined)
  }

  render () {
    const { response, disposisi } = this.state
    const { error, loading, disposisis } = this.props.disposisi
    const { clearError, setSuratMasukTemp } = this.props

    const { disposisi: disposisiTarget } = this.props.temp

    // mapping for data tables
    const mapData: Row[] = disposisis
    const columns: Table['columns'] = [
      {
        field: '_id',
        title: 'ID',
        hidden: !disposisiTarget ? true : false,
        editable: 'never'
      },
      {
        field: 'perintah',
        title: 'Perintah'
      },
      {
        field: 'catatan',
        title: 'Catatan'
      },
      {
        field: 'surat_masuk',
        title: 'Surat Masuk',
        disableClick: true,
        render: ({ surat_masuk: { _id } }) => (
          <Tooltip title='Show'>
            <Link className='link' to='/dashboard/surat-masuk'>
              <IconButton onClick={() => setSuratMasukTemp(_id)}>
                <MoreIcon />
              </IconButton>
            </Link>
          </Tooltip>
        ),
        editable: 'never'
      }
    ]
    const data: Table['data'] = mapData
    const editable: Table['editable'] = {
      onRowDelete: async oldData => {
        this.props.deleteDisposisi(oldData._id)
      }
    }
    const actions: Table['actions'] = [
      {
        icon: () => <EditIcon />,
        tooltip: 'Edit',
        onClick: (_, rowData) => this.openDisposisiDialog(rowData as Row)
      }
    ]

    return (
      <div>
        <MySnackbar
          open={error ? true : false}
          message={error}
          onClose={clearError} />
        <MainTitle title='Disposisi'/>
        <TableComponent<Row>
          isLoading={loading}
          columns={columns}
          data={data}
          editable={editable}
          actions={actions}
          onRowClick={(_, rowData) => this.openResponseDialog(rowData)}
          options={{
            search: disposisiTarget ? true : false,
            searchText: disposisiTarget || ''
          }}/>
        <DisposisiDialog
          open={response.open}
          setOpen={open => this.setState({ response: { ...response, open } })}
          data={response.data}/>
        <PimpinanDisposisiDialog
          suratMasukId=''
          open={disposisi.open}
          setOpen={open => this.setState({ disposisi: { ...disposisi, open } })}
          data={disposisi.data} />
      </div>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisposisiPage)
