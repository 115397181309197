import { ThunkActionType } from './_thunk'
import { gqlRequest, GraphQLResponse } from './_graphql'
import {
  REQUEST_FETCH_DISPOSISIS,
  REQUEST_CREATE_DISPOSISI,
  REQUEST_UPDATE_DISPOSISI,
  REQUEST_DELETE_DISPOSISI,
  REQUEST_DISPOSISI_ERROR,
  SET_DISPOSISIS,
  CLEAR_DISPOSISI,
  CLEAR_DISPOSISI_ERROR
} from '../types/disposisi'
import { ID } from '../../graphql'
import { Disposisi, DisposisiInput, DisposisiUpdateInput } from '../../graphql/disposisi'
import { fetchSuratMasuks } from './surat-masuk'

const FETCH_DISPOSISIS = () => ({
  query: `
    query { disposisis {
      _id catatan perintah surat_masuk {
        _id berkas nomor lampiran sumber isi status
        arsip { _id nama kode deskripsi }
        sifat { _id nama }
      }
      users { _id email nama nip balasan jabatan { nama } }
      createdAt updatedAt
    } }
  `
})
const CREATE_DISPOSISI = (data: DisposisiInput) => ({
  query: `
    mutation createDisposisi ($data: DisposisiInput!) {
      createDisposisi (data: $data) { _id }
    }
  `,
  variables: { data }
})
const UPDATE_DISPOSISI = (_id: ID, data: DisposisiUpdateInput) => ({
  query: `
    mutation updateDisposisi ($_id: ID! $data: DisposisiUpdateInput!) {
      updateDisposisi (_id: $_id data: $data) { _id }
    }
  `,
  variables: { _id, data }
})
const DELETE_DISPOSISI = (_id: ID) => ({
  query: `
    mutation deleteDisposisi ($_id: ID!) {
      deleteDisposisi (_id: $_id) { _id }
    }
  `,
  variables: { _id }
})

export function fetchDisposisis (): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_FETCH_DISPOSISIS })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      const response = await gqlRequest<GraphQLResponse<{disposisis: Disposisi[]}>>(FETCH_DISPOSISIS(), auth)
      dispatch({
        type: SET_DISPOSISIS,
        payload: response.disposisis
      })
    } catch (err) {
      dispatch({
        type: REQUEST_DISPOSISI_ERROR,
        payload: err.message
      })
    }
  }
}

export function createDisposisi (data: DisposisiInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_CREATE_DISPOSISI })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{createDisposisi: Disposisi}>>(CREATE_DISPOSISI(data), auth)
      await fetchDisposisis()(dispatch, getState, {})
      await fetchSuratMasuks()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_DISPOSISI_ERROR,
        payload: err.message
      })
    }
  }
}

export function updateDisposisi (_id: ID, data: DisposisiUpdateInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_UPDATE_DISPOSISI })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{updateDisposisi: Disposisi}>>(UPDATE_DISPOSISI(_id, data), auth)
      await fetchDisposisis()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_DISPOSISI_ERROR,
        payload: err.message
      })
    }
  }
}

export function deleteDisposisi (_id: ID): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_DISPOSISI })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{deleteDisposisi: Disposisi}>>(DELETE_DISPOSISI(_id), auth)
      await fetchDisposisis()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_DISPOSISI_ERROR,
        payload: err.message
      })
    }
  }
}

export function clearDisposisi (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_DISPOSISI })
  }
}

export function clearError (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_DISPOSISI_ERROR })
  }
}
