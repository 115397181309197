import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { connect } from 'react-redux'

import PublicRoute from './public'
import PrivateRoute from './private'
import { RootStates } from '../store/reducers'
import NotFoundDashboardPage from '../pages/exceptions/not-found-dashboard'
import NotFoundPage from '../pages/exceptions/not-found'
import MyDrawer from '../components/drawer'

const mapStateToProps = ({ session }: RootStates) => ({ session })

type Props = ReturnType<typeof mapStateToProps>

const Routes: React.FC<Props> = (props) => {
  const privateRoutesPrefix = '/dashboard'

  const publicRoutes = PublicRoute
  const privateRoutes = PrivateRoute(
    privateRoutesPrefix,
    props.session.info?.jabatan.role.level
  )

  return (
    <Router>
      <Switch>
        {
          publicRoutes.map((page, i) => (
            <Route
              key={i}
              path={page.path}
              exact={page.exact}
              component={page.component} />
          ))
        }
        <Route exact path={`${privateRoutesPrefix}/:menu?`}>
          {
            privateRoutes.length > 0
              ? <React.Fragment>
                  <nav>
                    <MyDrawer routes={privateRoutes}/>
                  </nav>
                  <main className='dashboard'>
                    <Switch>
                      {
                        privateRoutes.map((page, i) => (
                          <Route
                            key={i}
                            path={page.path}
                            exact={page.exact}
                            component={page.component}/>
                        ))
                      }
                      <Route component={NotFoundDashboardPage}/>
                    </Switch>
                  </main>
                </React.Fragment>
              : <Redirect to='/login'/>
          }
        </Route>
        <Route component={NotFoundPage}/>
      </Switch>
    </Router>
  )
}

export default connect(mapStateToProps)(Routes)
