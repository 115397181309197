import { Sifat as SifatSchema } from '../../graphql/sifat'

export const REQUEST_FETCH_SIFATS = 'REQUEST_FETCH_SIFATS'
export const REQUEST_CREATE_SIFAT = 'REQUEST_CREATE_SIFAT'
export const REQUEST_UPDATE_SIFAT = 'REQUEST_UPDATE_SIFAT'
export const REQUEST_DELETE_SIFAT = 'REQUEST_DELETE_SIFAT'
export const REQUEST_SIFAT_ERROR = 'REQUEST_SIFAT_ERROR'
export const SET_SIFATS = 'SET_SIFATS'
export const CLEAR_SIFAT = 'CLEAR_SIFAT'
export const CLEAR_SIFAT_ERROR = 'CLEAR_SIFAT_ERROR'

export type Sifat = SifatSchema

export type SifatState = {
  sifats: Sifat[]
  loading: boolean
  error: string | null
}

export type RequestFetchSifatsAction = {
  type: typeof REQUEST_FETCH_SIFATS
}

export type RequestCreateSifatAction = {
  type: typeof REQUEST_CREATE_SIFAT
}

export type RequestUpdateSifatAction = {
  type: typeof REQUEST_UPDATE_SIFAT
}

export type RequestDeleteSifatAction = {
  type: typeof REQUEST_DELETE_SIFAT
}

export type RequestSifatErrorAction = {
  type: typeof REQUEST_SIFAT_ERROR
  payload: string
}

export type SetSifatsAction = {
  type: typeof SET_SIFATS
  payload: Sifat[]
}

export type ClearSifatAction = {
  type: typeof CLEAR_SIFAT
}

export type ClearSifatErrorAction = {
  type: typeof CLEAR_SIFAT_ERROR
}

export type SifatActions =
  RequestFetchSifatsAction |
  RequestCreateSifatAction |
  RequestUpdateSifatAction |
  RequestDeleteSifatAction |
  RequestSifatErrorAction |
  SetSifatsAction |
  ClearSifatAction |
  ClearSifatErrorAction
