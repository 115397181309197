import {
  SifatState,
  SifatActions,
  REQUEST_FETCH_SIFATS,
  REQUEST_CREATE_SIFAT,
  REQUEST_UPDATE_SIFAT,
  REQUEST_DELETE_SIFAT,
  REQUEST_SIFAT_ERROR,
  SET_SIFATS,
  CLEAR_SIFAT,
  CLEAR_SIFAT_ERROR
} from '../types/sifat'

const initialState: SifatState = {
  sifats: [],
  loading: false,
  error: null
}

export default function (state = initialState, action: SifatActions): SifatState {

  switch (action.type) {

    case REQUEST_FETCH_SIFATS:
    case REQUEST_CREATE_SIFAT:
    case REQUEST_UPDATE_SIFAT:
    case REQUEST_DELETE_SIFAT:
      return {
        ...state,
        loading: true,
        error: null
      }

    case REQUEST_SIFAT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case SET_SIFATS:
      return {
        ...state,
        loading: false,
        error: null,
        sifats: action.payload
      }

    case CLEAR_SIFAT:
      return initialState

    case CLEAR_SIFAT_ERROR:
      return {
        ...state,
        loading: false,
        error: null
      }

    default:
      return state

  }

}
