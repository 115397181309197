import {
  UserState,
  UserActions,
  REQUEST_FETCH_USERS,
  REQUEST_CREATE_USER,
  REQUEST_UPDATE_USER,
  REQUEST_DELETE_USER,
  REQUEST_USER_ERROR,
  SET_USERS,
  CLEAR_USER,
  CLEAR_USER_ERROR
} from '../types/user'

const initialState: UserState = {
  users: [],
  loading: false,
  error: null
}

export default function (state = initialState, action: UserActions): UserState {

  switch (action.type) {

    case REQUEST_FETCH_USERS:
    case REQUEST_CREATE_USER:
    case REQUEST_UPDATE_USER:
    case REQUEST_DELETE_USER:
      return {
        ...state,
        loading: true,
        error: null
      }

    case REQUEST_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case SET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null
      }

    case CLEAR_USER:
      return initialState

    case CLEAR_USER_ERROR:
      return {
        ...state,
        error: null,
        loading: false
      }

    default:
      return state

  }

}
