import { ThunkActionType } from './_thunk'
import { gqlRequest, GraphQLResponse } from './_graphql'
import {
  REQUEST_FETCH_SURAT_KELUARS,
  REQUEST_CREATE_SURAT_KELUAR,
  REQUEST_UPDATE_SURAT_KELUAR,
  REQUEST_DELETE_SURAT_KELUAR,
  REQUEST_SURAT_KELUAR_ERROR,
  SET_SURAT_KELUARS,
  CLEAR_SURAT_KELUAR,
  CLEAR_SURAT_KELUAR_ERROR
} from '../types/surat-keluar'
import { ID } from '../../graphql'
import { SuratKeluar, SuratKeluarInput } from '../../graphql/surat-keluar'

const FETCH_SURAT_KELUARS = () => ({
  query: `
    query { suratKeluars {
      _id nomor lampiran tujuan isi status
      arsip { _id nama kode deskripsi }
      sifat { _id nama }
      kebutuhan_accs { _id nama jabatan lingkup nip }
      createdAt updatedAt
    } }
  `
})
const CREATE_SURAT_KELUAR = (data: SuratKeluarInput) => ({
  query: `
    mutation createSuratKeluar ($data: SuratKeluarInput!) {
      createSuratKeluar (data: $data) { _id }
    }
  `,
  variables: { data }
})
const UPDATE_SURAT_KELUAR = (_id: ID, data: SuratKeluarInput) => ({
  query: `
    mutation updateSuratKeluar ($_id: ID! $data: SuratKeluarInput!) {
      updateSuratKeluar (_id: $_id data: $data) { _id }
    }
  `,
  variables: { _id, data }
})
const DELETE_SURAT_KELUAR = (_id: ID) => ({
  query: `
    mutation deleteSuratKeluar ($_id: ID!) {
      deleteSuratKeluar (_id: $_id) { _id }
    }
  `,
  variables: { _id }
})
const ACC_SURAT_KELUAR = (_id: ID, status: number) => ({
  query:`
    mutation setAccSuratKeluar ($_id: ID! $status: Int!) {
      setAccSuratKeluar(_id: $_id status: $status) {
        _id
      }
    }
  `,
  variables: { _id, status }
})

export function fetchSuratKeluars (): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_FETCH_SURAT_KELUARS })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      const response = await gqlRequest<GraphQLResponse<{suratKeluars: SuratKeluar[]}>>(FETCH_SURAT_KELUARS(), auth)
      dispatch({
        type: SET_SURAT_KELUARS,
        payload: response.suratKeluars
      })
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_KELUAR_ERROR,
        payload: err.message
      })
    }
  }
}

export function createSuratKeluar (data: SuratKeluarInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_CREATE_SURAT_KELUAR })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{createSuratKeluar: SuratKeluar}>>(CREATE_SURAT_KELUAR(data), auth)
      await fetchSuratKeluars()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_KELUAR_ERROR,
        payload: err.message
      })
    }
  }
}

export function updateSuratKeluar (_id: ID, data: SuratKeluarInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_UPDATE_SURAT_KELUAR })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{updateSuratKeluar: SuratKeluar}>>(UPDATE_SURAT_KELUAR(_id, data), auth)
      await fetchSuratKeluars()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_KELUAR_ERROR,
        payload: err.message
      })
    }
  }
}

export function deleteSuratKeluar (_id: ID): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_SURAT_KELUAR })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{deleteSuratKeluar: SuratKeluar}>>(DELETE_SURAT_KELUAR(_id), auth)
      await fetchSuratKeluars()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_KELUAR_ERROR,
        payload: err.message
      })
    }
  }
}

export function accSuratKeluar (_id: ID, status: number): ThunkActionType {
  return async (dispatch, getState) => {
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{setAccSuratKeluar: SuratKeluar}>>(ACC_SURAT_KELUAR(_id, status), auth)
      await fetchSuratKeluars()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SURAT_KELUAR_ERROR,
        payload: err.message
      })
    }
  }
}

export function clearSuratKeluar (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_SURAT_KELUAR })
  }
}

export function clearError (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_SURAT_KELUAR_ERROR })
  }
}
