import { SuratMasukDisposisi as SuratMasukDisposisiSchema } from '../../graphql/surat-masuk'

export const REQUEST_FETCH_SURAT_MASUK_DISPOSISIS = 'REQUEST_SURAT_MASUK_DISPOSISIS'
export const REQUEST_CREATE_DISPOSISI_RESPONSE = 'REQUEST_CREATE_DISPOSISI_RESPONSE'
export const REQUEST_UPDATE_DISPOSISI_RESPONSE = 'REQUEST_UPDATE_DISPOSISI_RESPONSE'
export const REQUEST_SURAT_MASUK_DISPOSISI_ERROR = 'REQUEST_SURAT_MASUK_DISPOSISI_ERROR'
export const SET_SURAT_MASUK_DISPOSISIS = 'SET_SURAT_MASUK_DISPOSISIS'
export const CLEAR_SURAT_MASUK_DISPOSISI = 'CLEAR_SURAT_MASUK_DISPOSISI'
export const CLEAR_SURAT_MASUK_DISPOSISI_ERROR = 'CLEAR_SURAT_MASUK_DISPOSISI_ERROR'

export type SuratMasukDisposisi = SuratMasukDisposisiSchema

export type SuratMasukDisposisiState = {
  suratMasukDisposisis: SuratMasukDisposisi[]
  loading: boolean
  error: string | null
}

export type RequestFetchSuratMasukDisposisisAction = {
  type: typeof REQUEST_FETCH_SURAT_MASUK_DISPOSISIS
}

export type RequestCreateDisposisiResponseAction = {
  type: typeof REQUEST_CREATE_DISPOSISI_RESPONSE
}

export type RequestUpdateDisposisiResponseAction = {
  type: typeof REQUEST_UPDATE_DISPOSISI_RESPONSE
}

export type RequestSuratMasukDisposisiErrorAction = {
  type: typeof REQUEST_SURAT_MASUK_DISPOSISI_ERROR
  payload: string
}

export type SetSuratMasukDisposisisAction = {
  type: typeof SET_SURAT_MASUK_DISPOSISIS
  payload: SuratMasukDisposisi[]
}

export type ClearSuratMasukDisposisiAction = {
  type: typeof CLEAR_SURAT_MASUK_DISPOSISI
}

export type ClearSuratMasukDisposisiErrorAction = {
  type: typeof CLEAR_SURAT_MASUK_DISPOSISI_ERROR
}

export type SuratMasukDisposisiActions =
  RequestFetchSuratMasukDisposisisAction |
  RequestCreateDisposisiResponseAction |
  RequestUpdateDisposisiResponseAction |
  RequestSuratMasukDisposisiErrorAction |
  SetSuratMasukDisposisisAction |
  ClearSuratMasukDisposisiAction |
  ClearSuratMasukDisposisiErrorAction
