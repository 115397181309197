import React from 'react'
import { connect } from 'react-redux'
import { MaterialTableProps } from 'material-table'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/AddBox'
import PrintIcon from '@material-ui/icons/PrintOutlined'

import { RootStates } from '../../../store/reducers'
import { ThunkDispatchType } from '../../../store/actions/_thunk'
import { PRINT_URI } from '../../../config'
import { ID } from '../../../graphql'
import { KebutuhanAcc } from '../../../graphql/kebutuhan-acc'
import {
  fetchSuratKeluars,
  deleteSuratKeluar,
  clearError as suratKeluarClearError
} from '../../../store/actions/surat-keluar'
import {
  fetchSifats,
  clearError as sifatClearError
} from '../../../store/actions/sifat'
import {
  fetchArsips,
  clearError as arsipClearError
} from '../../../store/actions/arsip'
import MainTitle from '../../../components/main-title'
import MySnackbar from '../../../components/snackbar'
import TableComponent from '../../../components/table'
import StatusBadge from '../../../components/status-badge'
import SuratKeluarDialog from '../../../components/surat-keluar-dialog'

const mapStateToProps = ({ suratKeluar, sifat, arsip }: RootStates) => ({
  suratKeluar, sifat, arsip
})
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  fetchSuratKeluars: () => dispatch(fetchSuratKeluars()),
  fetchSifats: () => dispatch(fetchSifats()),
  fetchArsips: () => dispatch(fetchArsips()),
  deleteSuratKeluar: (_id: ID) => dispatch(deleteSuratKeluar(_id)),
  clearError: () => {
    dispatch(suratKeluarClearError())
    dispatch(sifatClearError())
    dispatch(arsipClearError())
  }
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type State = {
  modal: {
    open: boolean
    suratKeluarId?: ID
  }
}

type Row = {
  _id: ID
  nomor: string
  lampiran?: number
  tujuan: string
  isi: string
  status: number
  arsip: string
  sifat: string
  kebutuhan_accs: KebutuhanAcc[]
}
type Table = MaterialTableProps<Row>

class SuratKeluarPage extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      modal: {
        open: false
      }
    }

    props.fetchArsips()
    props.fetchSifats()
    props.fetchSuratKeluars()
  }

  openSuratKeluarDialog = (suratKeluarId?: ID) => {
    const { modal } = this.state
    this.setState({
      modal: {
        ...modal,
        suratKeluarId, open: true
      }
    })
  }

  render () {
    const { modal } = this.state
    const { error: suratKeluarError, loading: suratKeluarLoading, suratKeluars } = this.props.suratKeluar
    const { error: sifatError, loading: sifatLoading, sifats } = this.props.sifat
    const { error: arsipError, loading: arsipLoading, arsips } = this.props.arsip

    const error = suratKeluarError || sifatError || arsipError
    const loading = suratKeluarLoading || sifatLoading || arsipLoading
    const { clearError } = this.props

    // mapping for data tables
    const mapSuratKeluars: Row[] = suratKeluars.map(suratKeluar => ({
      ...suratKeluar,
      sifat: suratKeluar.sifat._id,
      arsip: suratKeluar.arsip._id
    }))
    const columns: Table['columns'] = [
      {
        field: '_id',
        title: 'ID',
        hidden: true,
        editable: 'never'
      },
      {
        field: 'status',
        title: 'Status',
        editable: 'never',
        render: ({ status }) => <StatusBadge status={status}/>
      },
      {
        field: 'nomor',
        title: 'Nomor'
      },
      {
        field: 'tujuan',
        title: 'Tujuan'
      },
      {
        field: 'lampiran',
        title: 'Lampiran',
        render: ({ lampiran }) => lampiran ? `${lampiran} lembar` : '-'
      },
      {
        field: 'status',
        title: 'Cetak',
        editable: 'never',
        render: ({ status, _id }) => status !== 1
          ? null
          : (
              <Tooltip title='Print'>
                <IconButton href={`${PRINT_URI}?_id=${_id}`} target='_blank'>
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            )
      },
    ]
    const data: Table['data'] = mapSuratKeluars
    const editable: Table['editable'] = {
      onRowDelete: async oldData => {
        this.props.deleteSuratKeluar(oldData._id)
      }
    }
    const actions: Table['actions'] = [
      {
        icon: () => <EditIcon />,
        tooltip: 'More',
        onClick: (_e, rowData) => this.openSuratKeluarDialog((rowData as Row)._id)
      },
      {
        icon: () => <AddIcon />,
        tooltip: 'Add',
        isFreeAction: true,
        onClick: () => this.openSuratKeluarDialog()
      }
    ]

    return (
      <div>
        <MySnackbar
          open={error ? true : false}
          message={error}
          onClose={clearError} />
        <MainTitle title='Surat Keluar'/>
        <TableComponent<Row>
          isLoading={loading}
          columns={columns}
          data={data}
          editable={editable}
          actions={actions}/>
        <SuratKeluarDialog
          suratKeluarId={modal.suratKeluarId}
          open={modal.open}
          setOpen={(open) => this.setState({ modal: { ...modal, open } })}
          arsips={arsips}
          sifats={sifats}/>
      </div>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuratKeluarPage)
