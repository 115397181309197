import { Arsip as ArsipSchema } from '../../graphql/arsip'

export const REQUEST_FETCH_ARSIPS = 'REQUEST_FETCH_ARSIPS'
export const REQUEST_CREATE_ARSIP = 'REQUEST_CREATE_ARSIP'
export const REQUEST_UPDATE_ARSIP = 'REQUEST_UPDATE_ARSIP'
export const REQUEST_DELETE_ARSIP = 'REQUEST_DELETE_ARSIP'
export const REQUEST_ARSIP_ERROR = 'REQUEST_ARSIP_ERROR'
export const SET_ARSIPS = 'SET_ARSIPS'
export const CLEAR_ARSIP = 'CLEAR_ARSIP'
export const CLEAR_ARSIP_ERROR = 'CLEAR_ARSIP_ERROR'

export type Arsip = ArsipSchema

export type ArsipState = {
  arsips: Arsip[]
  loading: boolean
  error: string | null
}

export type RequestFetchArsipsAction = {
  type: typeof REQUEST_FETCH_ARSIPS
}

export type RequestCreateArsipAction = {
  type: typeof REQUEST_CREATE_ARSIP
}

export type RequestUpdateArsipAction = {
  type: typeof REQUEST_UPDATE_ARSIP
}

export type RequestDeleteArsipAction = {
  type: typeof REQUEST_DELETE_ARSIP
}

export type RequestArsipErrorAction = {
  type: typeof REQUEST_ARSIP_ERROR
  payload: string
}

export type SetArsipsAction = {
  type: typeof SET_ARSIPS
  payload: Arsip[]
}

export type ClearArsipAction = {
  type: typeof CLEAR_ARSIP
}

export type ClearArsipErrorAction = {
  type: typeof CLEAR_ARSIP_ERROR
}

export type ArsipActions =
  RequestFetchArsipsAction |
  RequestCreateArsipAction |
  RequestUpdateArsipAction |
  RequestDeleteArsipAction |
  RequestArsipErrorAction |
  SetArsipsAction |
  ClearArsipAction |
  ClearArsipErrorAction
