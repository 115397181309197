import { ThunkActionType } from './_thunk'
import { gqlRequest, GraphQLResponse } from './_graphql'
import {
  REQUEST_FETCH_USERS,
  REQUEST_CREATE_USER,
  REQUEST_UPDATE_USER,
  REQUEST_DELETE_USER,
  REQUEST_USER_ERROR,
  SET_USERS,
  CLEAR_USER,
  CLEAR_USER_ERROR
} from '../types/user'
import { ID } from '../../graphql'
import { User, UserInput } from '../../graphql/user'

const FETCH_USERS = () => ({
  query: `
    query { users {
      _id email nama nip balasan
      jabatan { _id nama role { nama level } }
      createdAt updatedAt
    } }
  `
})
const CREATE_USER = (data: UserInput) => ({
  query: `
    mutation createUser ($data: UserInput!) {
      createUser (data: $data) { _id }
    }
  `,
  variables: { data }
})
const UPDATE_USER = (_id: ID, data: UserInput) => ({
  query: `
    mutation updateUser ($_id: ID! $data: UserInput!) {
      updateUser (_id: $_id data: $data) { _id }
    }
  `,
  variables: { _id, data }
})
const DELETE_USER = (_id: ID) => ({
  query: `
    mutation deleteUser ($_id: ID!) {
      deleteUser (_id: $_id) { _id }
    }
  `,
  variables: { _id }
})

export function fetchUsers (): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_FETCH_USERS })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      const response = await gqlRequest<GraphQLResponse<{users: User[]}>>(FETCH_USERS(), auth)
      dispatch({
        type: SET_USERS,
        payload: response.users
      })
    } catch (err) {
      dispatch({
        type: REQUEST_USER_ERROR,
        payload: err.message
      })
    }
  }
}

export function createUser (data: UserInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_CREATE_USER })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{createUser: User}>>(CREATE_USER(data), auth)
      await fetchUsers()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_USER_ERROR,
        payload: err.message
      })
    }
  }
}

export function updateUser (_id: ID, data: UserInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_UPDATE_USER })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{updateUser: User}>>(UPDATE_USER(_id, data), auth)
      await fetchUsers()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_USER_ERROR,
        payload: err.message
      })
    }
  }
}

export function deleteUser (_id: ID): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_USER })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{deleteUser: User}>>(DELETE_USER(_id), auth)
      await fetchUsers()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_USER_ERROR,
        payload: err.message
      })
    }
  }
}

export function clearUser (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_USER })
  }
}

export function clearError (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_USER_ERROR })
  }
}
