import { RouteProps } from 'react-router-dom'

import MainPage from '../pages/main'
import LoginPage from '../pages/login'

const publicPages: RouteProps[] = [
  {
    path: '/',
    exact: true,
    component: MainPage
  },
  {
    path: '/login',
    exact: false,
    component: LoginPage
  }
]

export default publicPages
