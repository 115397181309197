import React from 'react'
import { connect } from 'react-redux'
import { MaterialTableProps } from 'material-table'

import required from '../../../utils/required'
import { RootStates } from '../../../store/reducers'
import { ThunkDispatchType } from '../../../store/actions/_thunk'
import { ID } from '../../../graphql'
import { UserInput } from '../../../graphql/user'
import {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
  clearError as userClearError
} from '../../../store/actions/user'
import {
  fetchJabatans,
  clearError as jabatanClearError
} from '../../../store/actions/jabatan'
import MainTitle from '../../../components/main-title'
import MySnackbar from '../../../components/snackbar'
import TableComponent from '../../../components/table'

const mapStateToProps = ({ user, jabatan }: RootStates) => ({ user, jabatan })
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  fetchUsers: () => dispatch(fetchUsers()),
  fetchJabatans: () => dispatch(fetchJabatans()),
  createUser: (data: UserInput) => dispatch(createUser(data)),
  updateUser: (_id: ID, data: UserInput) => dispatch(updateUser(_id, data)),
  deleteUser: (_id: ID) => dispatch(deleteUser(_id)),
  clearError: () => {
    dispatch(userClearError())
    dispatch(jabatanClearError())
  }
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type Row = {
  _id: ID
  email: string
  password: string
  nama: string
  nip?: string
  jabatan: string
}

type Table = MaterialTableProps<Row>

class UserPage extends React.Component<Props> {
  constructor (props: Props) {
    super(props)
    props.fetchJabatans()
    props.fetchUsers()
  }

  render () {
    const { error: userError, loading: userLoading, users } = this.props.user
    const { error: jabatanError, loading: jabatanLoading, jabatans } = this.props.jabatan

    const loading = jabatanLoading || userLoading
    const error = jabatanError || userError
    const { clearError } = this.props

    // mapping for data tables
    const lookupJabatans = jabatans.reduce((state, jabatan) => ({
      ...state,
      [jabatan._id]: jabatan.nama
    }), {})
    const mapUsers: Row[] = users.map(user => ({
      ...user,
      password: '',
      jabatan: user.jabatan._id
    }))
    const columns: Table['columns'] = [
      {
        field: '_id',
        title: 'ID',
        hidden: true,
        editable: 'never'
      },
      {
        field: 'email',
        title: 'Email'
      },
      {
        field: 'password',
        title: 'Password',
        render: () => '******'
      },
      {
        field: 'nama',
        title: 'Nama'
      },
      {
        field: 'nip',
        title: 'NIP',
        render: rowData => rowData.nip ? `${rowData.nip}` : '-',
        hidden: true
      },
      {
        field: 'jabatan',
        title: 'Jabatan',
        lookup: lookupJabatans
      }
    ]
    const data: Table['data'] = mapUsers
    const editable: Table['editable'] = {
      onRowAdd: async newData => {
        const data: UserInput = {
          email: newData.email,
          password: newData.password,
          nama: newData.nama,
          nip: newData.nip,
          jabatan: newData.jabatan
        }
        const validation = required('email|password|nama|jabatan', data)
        if (validation.error) {
          alert(validation.message)
          throw new Error(validation.message || '')
        } else
          this.props.createUser(data)
      },
      onRowUpdate: async (newData, oldData) => {
        const data: UserInput = {
          email: newData.email,
          password: newData.password,
          nama: newData.nama,
          nip: newData.nip,
          jabatan: newData.jabatan
        }
        const validation = required('email|password|nama|jabatan', data)
        if (validation.error) {
          alert(validation.message)
          throw new Error(validation.message || '')
        } else
          this.props.updateUser(oldData?._id || '', data)
      },
      onRowDelete: async oldData => {
        this.props.deleteUser(oldData._id)
      }
    }

    return (
      <div>
        <MySnackbar
          open={error ? true : false}
          message={error}
          onClose={clearError} />
        <MainTitle title='Pengguna'/>
        <TableComponent<Row>
          isLoading={loading}
          columns={columns}
          data={data}
          editable={editable}/>
      </div>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPage)
