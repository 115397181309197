import { combineReducers } from 'redux'

import session from './session'
import user from './user'
import jabatan from './jabatan'
import role from './role'
import arsip from './arsip'
import sifat from './sifat'
import suratMasuk from './surat-masuk'
import suratKeluar from './surat-keluar'
import disposisi from './disposisi'
import suratMasukDisposisi from './surat-masuk-disposisi'
import temp from './temp'

const rootReducer = combineReducers({
  session, user, jabatan, role, arsip,
  sifat, suratMasuk, suratKeluar, disposisi,
  temp, suratMasukDisposisi
})

export type RootStates = ReturnType<typeof rootReducer>
export default rootReducer
