import React from 'react'
import { connect } from 'react-redux'
import { MaterialTableProps } from 'material-table'

import required from '../../../utils/required'
import { RootStates } from '../../../store/reducers'
import { ThunkDispatchType } from '../../../store/actions/_thunk'
import { ID } from '../../../graphql'
import { Sifat, SifatInput } from '../../../graphql/sifat'
import {
  fetchSifats,
  createSifat,
  updateSifat,
  deleteSifat,
  clearError
} from '../../../store/actions/sifat'
import MainTitle from '../../../components/main-title'
import MySnackbar from '../../../components/snackbar'
import TableComponent from '../../../components/table'

const mapStateToProps = ({ sifat }: RootStates) => ({ sifat })
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  fetchSifats: () => dispatch(fetchSifats()),
  createSifat: (data: SifatInput) => dispatch(createSifat(data)),
  updateSifat: (_id: ID, data: SifatInput) => dispatch(updateSifat(_id, data)),
  deleteSifat: (_id: ID) => dispatch(deleteSifat(_id)),
  clearError: () => dispatch(clearError())
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type Row = {
  _id: ID
  nama: string
  surat_masuks: Sifat['surat_masuks']
  surat_keluars: Sifat['surat_keluars']
}

type Table = MaterialTableProps<Row>

class SifatPage extends React.Component<Props> {
  constructor (props: Props) {
    super(props)
    props.fetchSifats()
  }

  render () {
    const { sifats, loading, error } = this.props.sifat
    const { clearError } = this.props

    const data: Table['data'] = sifats
    const columns: Table['columns'] = [
      {
        field: '_id',
        title: 'ID',
        hidden: true,
        editable: 'never'
      },
      {
        field: 'nama',
        title: 'Nama'
      }
    ]
    const editable: Table['editable'] = {
      isDeletable: rowData => (rowData.surat_masuks.length + rowData.surat_keluars.length) <= 0,
      onRowAdd: async newData => {
        const data: SifatInput = {
          nama: newData.nama
        }
        const validation = required('nama', data)
        if (validation.error) {
          alert(validation.message)
          throw new Error(validation.message || '')
        } else
          this.props.createSifat(data)
      },
      onRowUpdate: async (newData, oldData) => {
        const data: SifatInput = {
          nama: newData.nama
        }
        const validation = required('nama', data)
        if (validation.error) {
          alert(validation.message)
          throw new Error(validation.message || '')
        } else
          this.props.updateSifat(oldData?._id || '', data)
      },
      onRowDelete: async oldData => {
        this.props.deleteSifat(oldData._id)
      }
    }

    return (
      <div>
        <MySnackbar
          open={error ? true : false}
          message={error}
          onClose={clearError} />
        <MainTitle title='Sifat'/>
        <TableComponent<Row>
          isLoading={loading}
          columns={columns}
          data={data}
          editable={editable}/>
      </div>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SifatPage)
