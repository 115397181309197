import { createStore, compose, applyMiddleware } from 'redux'
import {
  persistStore,
  persistReducer,
  PersistConfig
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import reducer, { RootStates } from './reducers'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose
  }
}

const persistConfig: PersistConfig<RootStates> = {
  key: 'root',
  storage,
  whitelist: ['session']
}
const persistedReducer = persistReducer(persistConfig, reducer)

const enhancer = window.__REDUX_DEVTOOLS_EXTENSION__ || compose
export const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(thunk),
    enhancer()
  )
)

export const persistor = persistStore(store)
