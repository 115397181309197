import React, { useState, useEffect } from 'react'
import MaterialTable, {
  MaterialTableProps,
  MTableToolbar
} from 'material-table'
import {
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles'
import pink from '@material-ui/core/colors/pink'
import grey from '@material-ui/core/colors/grey'

function Table <T extends Object> (props: MaterialTableProps<T>) {
  const [dim, setDim] = useState({ width: window.innerWidth, height: window.innerHeight })
  const updateDim = () => setDim({
    width: window.innerWidth,
    height: window.innerHeight
  })
  useEffect(() => {
    window.addEventListener('resize', updateDim)
    return () => {
      window.removeEventListener('resize', updateDim)
    }
  })

  const tableHeight = dim.width < 960
    ? dim.width < 600 ? '62vh' : '60vh'
    : dim.width < 1280 ? '54vh' :'59vh'
  const theme = createMuiTheme({
    palette: {
      primary: { main: pink['A400'] }
    },
    overrides: {
      MuiTableCell: {
        root: props.data.length <= 0
          ? { borderBottom: 0 }
          : { }
      }
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
      <div className='material-table'>
        <MaterialTable
          {...props}
          components={{
            Toolbar: props => (
              <div
                style={{ background: grey[50] }}
                className='toolbar'>
                <MTableToolbar {...props}/>
              </div>
            )
          }}
          options={{
            minBodyHeight: tableHeight,
            maxBodyHeight: tableHeight,
            search: false,
            columnsButton: true,
            headerStyle: {
              background: grey[50]
            },
            pageSize: 5,
            pageSizeOptions: [5,10,20],
            loadingType: 'linear',
            showTitle: false,
            paginationType: 'stepped',
            ...props.options
          }}/>
      </div>
    </MuiThemeProvider>
  )
}

export default Table
