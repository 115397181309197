import React from 'react'
import { connect } from 'react-redux'
import { MaterialTableProps } from 'material-table'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import UploadIcon from '@material-ui/icons/CloudUploadOutlined'
import DownloadIcon from '@material-ui/icons/GetAppOutlined'

import required from '../../../utils/required'
import { RootStates } from '../../../store/reducers'
import { ThunkDispatchType } from '../../../store/actions/_thunk'
import { STORAGE_URI } from '../../../config'
import { ID } from '../../../graphql'
import { SuratMasuk, SuratMasukInput } from '../../../graphql/surat-masuk'
import {
  fetchSuratMasuks,
  createSuratMasuk,
  updateSuratMasuk,
  deleteSuratMasuk,
  clearError as suratMasukClearError
} from '../../../store/actions/surat-masuk'
import {
  fetchSifats,
  clearError as sifatClearError
} from '../../../store/actions/sifat'
import {
  fetchArsips,
  clearError as arsipClearError
} from '../../../store/actions/arsip'
import MainTitle from '../../../components/main-title'
import MySnackbar from '../../../components/snackbar'
import TableComponent from '../../../components/table'
import DisposisiDialog from '../../../components/disposisi-dialog'

const mapStateToProps = ({ suratMasuk, sifat, arsip }: RootStates) => ({
  suratMasuk, sifat, arsip
})
const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  fetchSuratMasuks: () => dispatch(fetchSuratMasuks()),
  fetchSifats: () => dispatch(fetchSifats()),
  fetchArsips: () => dispatch(fetchArsips()),
  createSuratMasuk: (data: SuratMasukInput) => dispatch(createSuratMasuk(data)),
  updateSuratMasuk: (_id: ID, data: SuratMasukInput) => dispatch(updateSuratMasuk(_id, data)),
  deleteSuratMasuk: (_id: ID) => dispatch(deleteSuratMasuk(_id)),
  clearError: () => {
    dispatch(suratMasukClearError())
    dispatch(sifatClearError())
    dispatch(arsipClearError())
  }
})

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type State = {
  disposisi: {
    open: boolean
    data?: SuratMasuk['disposisi']
  }
}

type Row = {
  _id: ID
  berkas: string
  nomor: string
  lampiran?: number
  sumber: string
  isi: string
  arsip: string
  sifat: string
  disposisi?: SuratMasuk['disposisi']
}

type Table = MaterialTableProps<Row>

class SuratMasukPage extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      disposisi: {
        open: false
      }
    }

    props.fetchArsips()
    props.fetchSifats()
    props.fetchSuratMasuks()
  }

  openDisposisiDialog = (data?: SuratMasuk['disposisi']) => {
    this.setState({
      disposisi: { open: true, data }
    })
  }

  render () {
    const { disposisi } = this.state
    const { error: suratMasukError, loading: suratMasukLoading, suratMasuks } = this.props.suratMasuk
    const { error: sifatError, loading: sifatLoading, sifats } = this.props.sifat
    const { error: arsipError, loading: arsipLoading, arsips } = this.props.arsip

    const error = suratMasukError || sifatError || arsipError
    const loading = suratMasukLoading || sifatLoading || arsipLoading
    const { clearError } = this.props

    // mapping for data tables
    const lookupArsips = arsips.reduce((state, arsip) => ({
      ...state,
      [arsip._id]: arsip.nama
    }), {})
    const lookupSifats = sifats.reduce((state, sifat) => ({
      ...state,
      [sifat._id]: sifat.nama
    }), {})
    const mapSuratMasuks: Row[] = suratMasuks.map(suratMasuk => ({
      ...suratMasuk,
      sifat: suratMasuk.sifat._id,
      arsip: suratMasuk.arsip._id
    }))
    const columns: Table['columns'] = [
      {
        field: '_id',
        title: 'ID',
        hidden: true,
        editable: 'never'
      },
      {
        field: 'nomor',
        title: 'Nomor'
      },
      {
        field: 'lampiran',
        title: 'Lampiran',
        type: 'numeric',
        hidden: true,
        render: rowData => rowData.lampiran ? `${rowData.lampiran} lembar` : '-'
      },
      {
        field: 'sumber',
        title: 'Sumber'
      },
      {
        field: 'isi',
        title: 'Isi'
      },
      {
        field: 'arsip',
        title: 'Arsip',
        lookup: lookupArsips
      },
      {
        field: 'sifat',
        title: 'Sifat',
        lookup: lookupSifats
      },
      {
        field: 'berkas',
        title: 'Berkas',
        disableClick: true,
        render: rowData => (
          <Tooltip title='Download'>
            <IconButton href={`${STORAGE_URI}/${rowData.berkas}`} target='_blank'>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        ),
        editComponent: props => {
          if (typeof props.value !== 'object') props.onChange(null)
          return (
            <div className='input-file'>
              <input
                style={{ display: 'none' }}
                accept='application/pdf'
                id='upload-file'
                type='file'
                onChange={e => props.onChange(
                  e.target.files ? e.target.files[0] : props.value
                )}/>
              <label htmlFor='upload-file'>
                <Tooltip title='Upload'>
                  <IconButton component='span'>
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
              </label>
              <TextField
                disabled
                placeholder={props.value ? (props.value.name || '') : ''}
                className='upload-file-label'/>
            </div>
          )
        }
      }
    ]
    const data: Table['data'] = mapSuratMasuks
    const editable: Table['editable'] = {
      isEditable: rowData => rowData.disposisi === null,
      onRowAdd: async newData => {
        let lampiran = parseInt(`${newData.lampiran || 0}`)
        lampiran = isNaN(lampiran) ? 0 : lampiran
        const data: SuratMasukInput = {
          berkas: newData.berkas,
          nomor: newData.nomor,
          lampiran,
          sumber: newData.sumber,
          isi: newData.isi,
          arsip: newData.arsip,
          sifat: newData.sifat,
        }
        const validation = required('berkas|nomor|sumber|isi|arsip|sifat', data)
        if (validation.error) {
          alert(validation.message)
          throw new Error(validation.message || '')
        } else
          this.props.createSuratMasuk(data)
      },
      onRowUpdate: async (newData, oldData) => {
        let lampiran = parseInt(`${newData.lampiran || 0}`)
        lampiran = isNaN(lampiran) ? 0 : lampiran
        const data: SuratMasukInput = {
          berkas: newData.berkas,
          nomor: newData.nomor,
          lampiran,
          sumber: newData.sumber,
          isi: newData.isi,
          arsip: newData.arsip,
          sifat: newData.sifat,
        }
        const validation = required('berkas|nomor|sumber|isi|arsip|sifat', data)
        if (validation.error) {
          alert(validation.message)
          throw new Error(validation.message || '')
        } else
          this.props.updateSuratMasuk(oldData?._id || '', data)
      },
      onRowDelete: async oldData => {
        this.props.deleteSuratMasuk(oldData._id)
      }
    }

    return (
      <div>
        <MySnackbar
          open={error ? true : false}
          message={error}
          onClose={clearError} />
        <MainTitle title='Surat Masuk'/>
        <TableComponent<Row>
          isLoading={loading}
          columns={columns}
          data={data}
          editable={editable}
          onRowClick={(_event, rowData) => (
            rowData && this.openDisposisiDialog(rowData.disposisi)
          )}/>
        <DisposisiDialog
          open={disposisi.open}
          data={disposisi.data}
          setOpen={open => this.setState({ disposisi: { ...disposisi, open } })}
          />
      </div>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuratMasukPage)
