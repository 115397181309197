import { ThunkActionType } from './_thunk'
import { gqlRequest, GraphQLResponse } from './_graphql'
import {
  REQUEST_FETCH_SIFATS,
  REQUEST_CREATE_SIFAT,
  REQUEST_UPDATE_SIFAT,
  REQUEST_DELETE_SIFAT,
  REQUEST_SIFAT_ERROR,
  SET_SIFATS,
  CLEAR_SIFAT,
  CLEAR_SIFAT_ERROR
} from '../types/sifat'
import { ID } from '../../graphql'
import { Sifat, SifatInput } from '../../graphql/sifat'

const FETCH_SIFATS = () => ({
  query: `
    query { sifats {
      _id nama createdAt updatedAt
      surat_masuks { _id }
      surat_keluars { _id }
    } }
  `
})
const CREATE_SIFAT = (data: SifatInput) => ({
  query: `
    mutation createSifat ($data: SifatInput!) {
      createSifat (data: $data) { _id }
    }
  `,
  variables: { data }
})
const UPDATE_SIFAT = (_id: ID, data: SifatInput) => ({
  query: `
    mutation updateSifat ($_id: ID! $data: SifatInput!) {
      updateSifat (_id: $_id data: $data) { _id }
    }
  `,
  variables: { _id, data }
})
const DELETE_SIFAT = (_id: ID) => ({
  query: `
    mutation deleteSifat ($_id: ID!) {
      deleteSifat (_id: $_id) { _id }
    }
  `,
  variables: { _id }
})

export function fetchSifats (): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_FETCH_SIFATS })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      const response = await gqlRequest<GraphQLResponse<{sifats: Sifat[]}>>(FETCH_SIFATS(), auth)
      dispatch({
        type: SET_SIFATS,
        payload: response.sifats
      })
    } catch (err) {
      dispatch({
        type: REQUEST_SIFAT_ERROR,
        payload: err.message
      })
    }
  }
}

export function createSifat (data: SifatInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_CREATE_SIFAT })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{createSifat: Sifat}>>(CREATE_SIFAT(data), auth)
      await fetchSifats()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SIFAT_ERROR,
        payload: err.message
      })
    }
  }
}

export function updateSifat (_id: ID, data: SifatInput): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_UPDATE_SIFAT })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{updateSifat: Sifat}>>(UPDATE_SIFAT(_id, data), auth)
      await fetchSifats()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SIFAT_ERROR,
        payload: err.message
      })
    }
  }
}

export function deleteSifat (_id: ID): ThunkActionType {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_SIFAT })
    try {
      const { session } = getState()
      const auth = `${session.info?.auth.token_type} ${session.info?.auth.token}`
      await gqlRequest<GraphQLResponse<{deleteSifat: Sifat}>>(DELETE_SIFAT(_id), auth)
      await fetchSifats()(dispatch, getState, {})
    } catch (err) {
      dispatch({
        type: REQUEST_SIFAT_ERROR,
        payload: err.message
      })
    }
  }
}

export function clearSifat (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_SIFAT })
  }
}

export function clearError (): ThunkActionType {
  return dispatch => {
    dispatch({ type: CLEAR_SIFAT_ERROR })
  }
}
