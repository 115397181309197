import {
  ArsipState,
  ArsipActions,
  REQUEST_FETCH_ARSIPS,
  REQUEST_CREATE_ARSIP,
  REQUEST_UPDATE_ARSIP,
  REQUEST_DELETE_ARSIP,
  REQUEST_ARSIP_ERROR,
  SET_ARSIPS,
  CLEAR_ARSIP,
  CLEAR_ARSIP_ERROR
} from '../types/arsip'

const initialState: ArsipState = {
  arsips: [],
  loading: false,
  error: null
}

export default function (state = initialState, action: ArsipActions): ArsipState {

  switch (action.type) {

    case REQUEST_FETCH_ARSIPS:
    case REQUEST_CREATE_ARSIP:
    case REQUEST_UPDATE_ARSIP:
    case REQUEST_DELETE_ARSIP:
      return {
        ...state,
        loading: true,
        error: null
      }

    case REQUEST_ARSIP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case SET_ARSIPS:
      return {
        ...state,
        loading: false,
        error: null,
        arsips: action.payload
      }

    case CLEAR_ARSIP:
      return initialState

    case CLEAR_ARSIP_ERROR:
      return {
        ...state,
        loading: false,
        error: null
      }

    default:
      return state

  }

}
